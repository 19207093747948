<template>
  <div
    v-if="!isLoadingInterview"
    class="flex flex-col md:flex-row justify-between gap-4"
  >
    <div
      v-if="interviewStatusData.text"
      :data-test="interviewStatusData.dataTest"
      class="p-section--content-description md:max-w-[60%]"
    >
      {{ $t(interviewStatusData.text) }}
      <br>
      {{ $t(interviewStatusData.textDesc) }}
    </div>
    <div class="flex flex-col md:flex-row gap-4 flex-shrink-0">
      <Button
        v-if="cta"
        class="max-h-12 flex-shrink-0"
        severity="secondary"
        :icon="interview.status === 'REPORT_READY' ? 'uil-download-alt' : ''"
        :data-test="interviewStatusData.ctaDataTest"
        :label="$t(interviewStatusData.ctaLabel)"
        @click="cta"
      />
      <Button
        v-if="interview.status === 'REPORT_READY'"
        class="max-h-12 flex-shrink-0"
        :link="true"
        icon="uil-arrow-right"
        icon-pos="right"
        :label="$t('user_profile.interview.go_collection')"
        @click="openInterviewSystemCollection"
      />
    </div>
    <InterviewModal
      v-model="showDialog"
      :mode="mode"
    />
  </div>
</template>

<script setup>
import {defineEmits, ref, computed} from "vue";
import {useRouter} from "vue-router";

  // name: "InterviewStatusSection"
import Button from "primevue/button";

import {useInterview} from "@/composables/useInterview";
import InterviewModal from "@/views/InterviewModal.vue";

const router = useRouter();

const emit = defineEmits(['close-dialog'])
const { isLoadingInterview, interview, interviewStatusData, getItwReport } = useInterview()

const showDialog = ref(false);
const mode = ref('initialize');

const openModal = () => {
  if (interview.value.status === 'CREATED') {
    mode.value = 'continue';
  }
  showDialog.value = true;
};
const getReportApi = async () => {
  await getItwReport().then((bytes) => {
    let blob = new Blob([bytes.data], { type: "application/pdf" });
    let url = window.URL || window.webkitURL;
    let l = url.createObjectURL(blob);

    var link = document.createElement("a");
    link.href = l;
    link.setAttribute("download", "report.pdf");
    link.click();
    link.remove();
  });
};
const cta = computed(() => {
  if (['CREATED', 'STARTED', 'FAILED', null].indexOf(interview.value.status) > -1) {
    return openModal;
  } else if (interview.value.status === 'REPORT_READY') {
    return getReportApi;
  }
  return null;
})


const openInterviewSystemCollection = () => {
  emit('close-dialog')
  router.push({ name: "collection-detail", params: { id: 'interview|interview', systemTitle: 'interview|interview' } });
}

</script>

<style scoped>

</style>

<template>
  <div
    v-if="!isLoadingQuestionnaire"
    class="flex flex-col md:flex-row justify-between gap-4"
  >
    <div
      v-if="questionnaireStatusData.text"
      :data-test="questionnaireStatusData.dataTest"
      class="p-section--content-description md:max-w-[60%]"
    >
      {{ $t(questionnaireStatusData.text) }}
      <br>
      {{ $t(questionnaireStatusData.textDesc) }}
    </div>
    <div class="flex flex-col md:flex-row gap-4 flex-shrink-0">
      <Button
        v-if="cta"
        class="max-h-12 flex-shrink-0"
        severity="secondary"
        :icon="questionnaire.status === 'REPORT_READY' ? 'uil-download-alt' : ''"
        :data-test="questionnaireStatusData.ctaDataTest"
        :label="$t(questionnaireStatusData.ctaLabel)"
        @click="cta"
      />
      <Button
        v-if="questionnaire.status === 'REPORT_READY'"
        class="max-h-12 flex-shrink-0"
        :link="true"
        icon="uil-arrow-right"
        icon-pos="right"
        :label="$t('user_profile.egg_up.go_collection')"
        @click="openEggUpSystemCollection"
      />
    </div>
    <EggupModal
      v-model="showDialog"
      :mode="mode"
    />
  </div>
</template>

<script setup>
import {defineEmits, ref, computed} from "vue";
import {useRouter} from "vue-router";

import Button from "primevue/button";

import {useQuestionnaire} from "@/composables/useQuestionnaire";
import EggupModal from "@/views/EggupModal.vue";

const router = useRouter();

const emit = defineEmits(['close-dialog'])
const { isLoadingQuestionnaire, questionnaire, questionnaireStatusData, getQuestReport } = useQuestionnaire()

const showDialog = ref(false);
const mode = ref('initialize');

const openModal = () => {
  if (questionnaire.value.status === 'CREATED') {
    mode.value = 'continue';
  }
  showDialog.value = true;
};
const getReportApi = async () => {
  await getQuestReport().then((bytes) => {
    let blob = new Blob([bytes.data], { type: "application/pdf" });
    let url = window.URL || window.webkitURL;
    let l = url.createObjectURL(blob);

    var link = document.createElement("a");
    link.href = l;
    link.setAttribute("download", "q_report.pdf");
    link.click();
    link.remove();
  });
};
const cta = computed(() => {
  if (['CREATED', 'STARTED', 'FAILED', null].indexOf(questionnaire.value.status) > -1) {
    return openModal;
  } else if (questionnaire.value.status === 'REPORT_READY') {
    return getReportApi;
  }
  return null;
})


const openEggUpSystemCollection = () => {
  emit('close-dialog')
  router.push({ name: "collection-detail", params: { id: 'eggup|'+questionnaire.value.session, systemTitle: 'eggup|'+questionnaire.value.session } });
}

</script>

<style scoped>

</style>

import {authorizedRequest, manageApiError, transformFilters} from "@/api";
import config from "@/config";

async function list(limit= 20, offset= 0, paramsArgs=null) {
  const filtersFormatted = transformFilters(paramsArgs.filters);
  const ordering = paramsArgs.sortField ? ((paramsArgs.sortOrder || 0) >= 0 ? '' : '-') + paramsArgs.sortField : '';
  const paginationParams = `limit=${limit}&offset=${offset}&ordering=${ordering}`;
  const params = filtersFormatted ? filtersFormatted+'&'+paginationParams : '?'+paginationParams;

  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/organizations/${params}`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function getById(id) {
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/organizations/${id}/`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function deleteById(id) {
  try {
    const result = await authorizedRequest().delete(config.BACKEND_URL+`/api/v2/drf/organizations/${id}/`);
    if (result.status === 204) {
      return { success: true };
    } else {
      return result.data;
    }
  } catch (error) {
    return manageApiError(error);
  }
}

async function create(data) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/organizations/`, data);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function patch(data) {
  try {
    const result = await authorizedRequest().patch(config.BACKEND_URL+`/api/v2/drf/organizations/${data.id}/`, data);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function createUser(id, user) {
  try {
    // const result = await authorizedRequest().post(`/organization/${id}/user`, user);
    // user.organization_id = id;
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/users/`,user);
    // const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/auth/users/`,user);
    return {error: false, result: result.data};
  } catch (error) {
      return manageApiError(error)
  }
}

async function updateUser(id, user) {
  try {
    // const result = await authorizedRequest().patch(`/organization/${id}/user/${user.id}`, user);
    const result = await authorizedRequest().patch(config.BACKEND_URL+`/api/v2/drf/users/${user.id}/`, user);
    return {error: false, result: result.data};
  } catch (error) {
      return manageApiError(error)
  }
}

async function importExcellUser(id, data) {
  try {
    // const result = await authorizedRequest().patch(`/organization/${id}/user/${user.id}`, user);
    const result = await authorizedRequest(false, true).post(config.BACKEND_URL+`/api/v2/drf/organizations/${id}/import_users_and_groups/`, data);
    return {error: false, result: result.data};
  } catch (error) {
      return manageApiError(error)
  }
}

async function deleteUser(id) {
  try {
    // const result = await authorizedRequest().patch(`/organization/${id}/user/${user.id}`, user);
    const result = await authorizedRequest().delete(config.BACKEND_URL+`/api/v2/drf/users/${id}/`);
    return {error: false, result: result.data};
  } catch (error) {
      return manageApiError(error)
  }
}
async function convertToPayInApp(id) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/organizations/${id}/convert_to_pay_in_app/`);
    return {error: false, result: result.data};
  } catch (error) {
      return manageApiError(error)
  }
}
// async function switchProfileUser(id, data) {
//   try {
//     const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/users/${id}/switch_profile/`, data);
//     return {error: false, result: result.data};
//   } catch (error) {
//       return manageApiError(error)
//   }
// }


// async function refreshUserCollections(id) {
//   try {
//     const result = await request().post(`/api/v1/update-collections/${id}`,{},{timeout: 25000});
//     return result.data;
//   } catch (error) {
//     return { error };
//   }
// }



export {
  list,
  getById,
  create,
  patch,
  deleteById,
  createUser,
  updateUser,
  deleteUser,
  convertToPayInApp,
  importExcellUser
  // switchProfileUser,
};

<template>
  <div
    v-if="(isAuthenticated || !$route.meta.requiresAuth) && (!refNeedsToChooseRole || (refNeedsToChooseRole && $route.name === 'welcome') || $route.name === 'organization-plans')"
    class="app-wrapper"
  >
    <page-spinner />
    <component
      :is="$route.meta.layout || 'div'"
      :search="$route.meta.search"
      :menu="$route.meta.menu"
    >
      <router-view @refresh-app="refreshNeedsToChooseRole" />
    </component>
    <!--    <modal-root />-->
  </div>
  <PreAuthentication v-else />
</template>

<script setup>
// import ModalRoot from '@/components/ModalRoot.vue'
import PageSpinner from '@/components/PageSpinner.vue'
import { useUserData } from '@/composables/userData'
import PreAuthentication from './views/PreAuthentication.vue';
import { useRouter } from 'vue-router';
import {onMounted, ref} from 'vue';
import { useUserRoles } from './composables/userData';
const { isAuthenticated } = useUserData()
const router = useRouter()
const { userNeedsToChooseRole } = useUserRoles()

const needsToChooseRole = userNeedsToChooseRole()
const refNeedsToChooseRole = ref(needsToChooseRole)
onMounted(() => {
  if (isAuthenticated.value && needsToChooseRole) {
    router.push({ name: 'welcome' })
  }
})
const refreshNeedsToChooseRole =() => {
  refNeedsToChooseRole.value = userNeedsToChooseRole()
}

</script>

<style>
@import './styles/all.css';
@import './styles/tailwind.css';
</style>

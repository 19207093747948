<template>
  <div class="w-full h-full pt-20 md:pt-16 bg-gray-100 px-4 sm:px-6">
    <div class="flex flex-col gap-6">
      <div class="flex justify-between items-center">
        <div class="font-bold text-gray-900 uppercase">
          {{ t('sidebar.for_you') }}
        </div>
        <Button
          icon="uil uil-graph-bar"
          :label="$t('for_you.modal')"
          size="small"
          severity="secondary"
          @click="showYourPathModal = true"
        />
      </div>
      <YourPathModal v-model="showYourPathModal" />
      <div class="flex flex-col">
        <div class="text-2xl font-extrabold">
          {{ t('for_you.title') }}
        </div>
        <div>
          {{ t('for_you.description') }}
        </div>
      </div>
      <div class="text-gray-900 font-bold uppercase">
        {{ t('for_you.role_based') }}
      </div>
      <div
        v-if="!isSystemCollectionLoading"
        class="flex gap-6 p-1 overflow-auto -mx-4 px-4 sm:-mx-6 sm:px-6 py-5 -my-5"
      >
        <ForYouCard
          v-for="(collection, idx) in systemCollectionsMapped"
          :key="collection.id"
          :title="collection.subtitle.split(' ')[0]"
          :new_content="collection.new"
          :subtitle="collection.subtitle.split(' ')[1]"
          :color="colors[idx % colors.length]"
          :class="{
            'gtm-cta-hardskills-hardskillssection': collection.classification === 'hard',
            'gtm-cta-softskills-softskillsssection': collection.classification === 'soft',
            'gtm-cta-techskills-techskillsssection': collection.classification === 'hot_tech',
          }"
          :description="
            collection.completed +
              '/' +
              collection.total +
              ' ' +
              (collection.completed === 1
                ? t('general.completed')
                : t('general.completeds'))
          "
          @click="
            $router.push({
              name: 'collection-detail',
              params: { id: collection.id },
            })
          "
        />
        <ForYouInterviewCard
          :description="
            interview?.completed +
              '/' +
              interview?.total +
              ' ' +
              (interview?.completed === 1
                ? t('general.completed')
                : t('general.completeds'))
          "
        />
        <ForYouEggupCard
          v-if="
            currentUser &&
              currentUser.addon_catalogs &&
              currentUser.addon_catalogs.indexOf('eggup') > -1
          "
          :description="
            eggup?.completed +
              '/' +
              eggup?.total +
              ' ' +
              (eggup?.completed === 1
                ? t('general.completed')
                : t('general.completeds'))
          "
        />
      </div>
      <div
        v-else
        class="flex gap-6 p-1 overflow-auto"
      >
        <SkeletonCard
          v-for="n in 4"
          :key="n"
        />
      </div>
      <div
        v-if="skillCollectionsMapped.length > 0"
        class="text-gray-900 font-bold uppercase"
      >
        {{ $t('for_you.skill_based') }}
      </div>
      <div class="flex flex-row gap-6 p-1 overflow-auto -mx-4 px-4 sm:-mx-6 sm:px-6 py-5 -my-5">
        <div
          v-if="skillCollectionsMapped.length === 1"
          class="w-full h-full bg-gray-100"
        >
          <div class="flex flex-col gap-6">
            <CardGroup
              v-for="item in skillCollectionsMapped"
              :id="item.id"
              :key="item.id"
              :name="item.systemTitle"
              :system-type="item.systemType"
              :type="item.source"
              :classification="item.classification"
              :only-create-mode="true"
            />
          </div>
        </div>
        <InterestCard
          v-for="(collection, idx) in skillCollectionsMapped"
          v-else
          :key="collection.id"
          :title="collection.systemTitle"
          :new_content="collection.new"
          :description="
            (collection.completed || 0) +
              '/' +
              (collection.total || 0) +
              ' ' +
              (collection.completed === 1 ? $t('general.completed') : $t('general.completeds'))
          "
          :color="colors[idx % colors.length]"
          @click="
            $router.push({
              name: 'collection-detail',
              params: { id: collection.id },
            })
          "
        />
      </div>
      <div class="flex flex-col">
        <div class="text-2xl font-extrabold">
          {{ $t('for_you.suggestions_title') }}
        </div>
        <div>
          {{ $t('for_you.suggestions_description') }}
        </div>
      </div>
      <div class="flex gap-6 p-1 overflow-auto -mx-4 px-4 sm:-mx-6 sm:px-6 py-5 -my-5">
        <ImageCard
          :is-loading="createdForYouLoading"
          :images="createdForYou"
          description=""
          :title="t('collections.system_created_for_you')"
          @click="
            $router.push({
              name: 'collection-detail',
              params: { id: 'suggestion|created_for_you' },
            })
          "
        />
        <ImageCard
          :is-loading="suggestedForYouLoading"
          :images="suggestedForYou"
          description=""
          :title="t('collections.system_suggested_for_you')"
          @click="
            $router.push({
              name: 'collection-detail',
              params: { id: 'suggestion|suggested_for_you' },
            })
          "
        />
        <ImageCard
          :is-loading="suggestedForYourRoleLoading"
          :images="suggestedForYourRole"
          description=""
          :title="t('collections.system_suggested_for_your_role')"
          @click="
            $router.push({
              name: 'collection-detail',
              params: { id: 'suggestion|suggested_for_your_role' },
            })
          "
        />
        <ImageCard
          :is-loading="suggestedForYourSkillsLoading"
          :images="suggestedForYourSkills"
          description=""
          :title="t('collections.system_suggested_for_your_skills')"
          @click="
            $router.push({
              name: 'collection-detail',
              params: { id: 'suggestion|suggested_for_your_skills' },
            })
          "
        />
        <ImageCard
          :is-loading="organizationTop10Loading"
          :images="organizationTop10"
          description=""
          :title="t('collections.system_organization_top_10')"
          @click="
            $router.push({
              name: 'collection-detail',
              params: { id: 'suggestion|organization_top_10' },
            })
          "
        />
      </div>
      <div
        v-if="globalCollectionsMapped.length > 0"
        class="flex flex-col"
      >
        <div class="text-2xl font-extrabold">
          {{ $t('for_you.radar_title') }}
        </div>
        <div>
          {{ $t('for_you.radar_description') }}
        </div>
      </div>
      <div
        v-if="!isGlobalCollectionLoading"
        class="flex flex-col md:flex-row gap-6 p-1 overflow-auto pb-12 -mx-4 px-4 sm:-mx-6 sm:px-6 py-5 -my-5"
      >
        <div
          v-if="globalCollectionsMapped.length === 1"
          class="w-full h-full bg-gray-100"
        >
          <div class="flex flex-col gap-6">
            <CardGroup
              v-for="item in globalCollectionsMapped"
              :id="item.id"
              :key="item.title"
              :name="item.title"
              :system-type="item.systemType"
              :type="item.source"
              :classification="item.classification"
              :only-create-mode="true"
            />
          </div>
        </div>
        <ExpertCard
          v-for="(collection, idx) in globalCollectionsMapped"
          v-else
          :key="idx"
          :title="collection.title"
          :resources="collection.resources"
          :name="collection.user_name"
          :description="
            collection.resources.length +
              ' ' +
              (collection.resources.length > 1
                ? t('general.contents')
                : t('general.content'))
          "
          @click="
            $router.push({
              name: 'collection-detail',
              params: { id: collection.id },
            })
          "
        />
      </div>
    </div>

    <Dialog
      v-model:visible="showOpenConsentModal"
      class="w-full sm:w-[600px] m-4"
      :modal="true"
      :closable="false"
      :draggable="false"
      :header="$t('agreement.title')"
    >
      <ModalAskForAgreement
        @close="() => {showOpenConsentModal = false}"
      />
    </Dialog>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCollectionsSystem } from '@/composables/useCollectionsSystem'
import { useUserData, useUserRoles } from '@/composables/userData.js'
import useSpecialCollection from '@/composables/useSpecialCollection'
import ModalAskForAgreement from '@/components/ModalAskForAgreement.vue'
import ForYouCard from '@/components/cards/ForYouCard.vue'
import Button from 'primevue/button'
import InterestCard from '@/components/cards/InterestCard.vue'
import ImageCard from '@/components/cards/ImageCard.vue'
import ExpertCard from '@/components/cards/ExpertCard.vue'
import SkeletonCard from '@/components/cards/SkeletonCard.vue'
import CardGroup from '@/components/CardGroup.vue'
import { useCollectionsGlobal } from '@/composables/useCollectionsGlobal'
import ForYouInterviewCard from '@/components/cards/ForYouInterviewCard.vue'
import ForYouEggupCard from '@/components/cards/ForYouEggupCard.vue'
import YourPathModal from '../components/YourPathModal.vue'
import Dialog from "primevue/dialog";

const showYourPathModal = ref(false)

const colors = ['blue', 'green', 'orange', 'red', 'gray']

const { t } = useI18n()
const { getUser } = useUserData()
const currentUser = getUser()
const { systemCollections, isSystemCollectionLoading } = useCollectionsSystem()
const { isGlobalCollectionLoading, globalCollections } = useCollectionsGlobal()
const { data: createdForYouData, isLoading: createdForYouLoading } =
  useSpecialCollection('created_for_you')
const { data: suggestedForYouData, isLoading: suggestedForYouLoading } =
  useSpecialCollection('suggested_for_you')
const {
  data: suggestedForYourRoleData,
  isLoading: suggestedForYourRoleLoading,
} = useSpecialCollection('suggested_for_your_role')
const {
  data: suggestedForYourSkillsData,
  isLoading: suggestedForYourSkillsLoading,
} = useSpecialCollection('suggested_for_your_skills')
const { data: organizationTop10Data, isLoading: organizationTop10Loading } =
  useSpecialCollection('organization_top_10')
const { userNeedsToChooseRole } = useUserRoles()

const showOpenConsentModal = ref(false);


const createdForYou = computed(() =>
  createdForYouData.value.map((c) => ({
    image: c.image.src,
  }))
)
const suggestedForYou = computed(() =>
  suggestedForYouData.value.map((c) => ({
    image: c.image.src,
  }))
)
const suggestedForYourRole = computed(() =>
  suggestedForYourRoleData.value.map((c) => ({
    image: c.image.src,
  }))
)
const suggestedForYourSkills = computed(() =>
  suggestedForYourSkillsData.value.map((c) => ({
    image: c.image.src,
  }))
)
const organizationTop10 = computed(() =>
  organizationTop10Data.value.map((c) => ({
    image: c.image.src,
  }))
)

const globalCollectionsMapped = computed(() => {
    if (!isGlobalCollectionLoading.value) {
      return globalCollections.value.map((c) => ({
        id: c.id,
        title: c.title,
        description: c.description,
        systemTitle: 'global',
        classification: c.classification,
        systemType: 'global',
        titleToDisplay: c.title,
        resources: c.resources_full,
        user_name: c.userFullName,
      }))
    } else {
      return []
    }
  }
)

const collectionToCard = (c) => ({
  id: c.title,
  title: c.title,
  completed: c.completed,
  classification: c.classification,
  new: c.new,
  source: c.source,
  total: c.resources.length,
  description: c.source === 'occupation' ? c.description : t(c.description),
  systemTitle: c.source === 'occupation' ? c.classification : c.systemTitle,
  systemType: c.systemType,
  resourcesIds: c.resources,
  subtitle: c.source === 'occupation'
    ? t('collections.system_' + c.classification)
    : c.systemTitle || c.title,
})

const systemCollectionsMapped = computed(() =>
  systemCollections.value
    .filter((c) => c.title.indexOf('occupation') > -1)
    .map(collectionToCard)
)
const interview = computed(
  () => {
    if (isSystemCollectionLoading.value) {
      return {}
    }
    let interviewCols = systemCollections.value.filter((c) => c.title.indexOf('interview') > -1)
    if (interviewCols) {
      return interviewCols.map(collectionToCard)[0]
    }
    return {}
  }
)
const eggup = computed(
  () => {
    if (isSystemCollectionLoading.value) {
      return {}
    }
    let eggUpCols = systemCollections.value.filter((c) => c.title.indexOf('eggup') > -1)
    if (eggUpCols) {
      return eggUpCols.map(collectionToCard)[0]
    }
    return {}
  }
)
const skillCollectionsMapped = computed(() =>
  systemCollections.value
    .filter((c) => c.title.indexOf('skill') > -1)
    .map(collectionToCard)
)

onMounted(async () => {
  const { isAuthenticated, user } = await useUserData()
  if (isAuthenticated.value && !user.made_choice && !userNeedsToChooseRole()) {
    showOpenConsentModal.value = true
  }
})
</script>

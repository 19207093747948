<template>
  <div class="w-full pt-20 md:pt-40 h-screen flex items-center flex-col bg-gray-100">
    <div class="max-w-[620px] px-6 py-8 m-4 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
      <div class="self-stretch flex-col justify-start items-center gap-2 flex">
        <div class="self-stretch text-center text-gray-900 text-2xl font-extrabold">
          {{ $t(`preauthentication.${section}.title`) }}
        </div>
      </div>
      <div class="self-stretch text-center text-gray-700 text-base font-normal font-['Nunito Sans'] leading-normal">
        {{ $t(`preauthentication.${section}.body`) }}
      </div>
      <ProgressSpinner />
    </div>
  </div>
</template>
<script setup>
import ProgressSpinner from 'primevue/progressspinner'
import { useSessionStorage } from '@vueuse/core';
const logout = useSessionStorage('logout', false);
const section = logout.value ? 'logout' : 'login';
</script>

<style>
.p-progress-spinner-circle {
  stroke: #004BE5 !important;
}
</style>

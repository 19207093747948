<template>
  <div>
    <InputWrapper
      name="skills"
      label="users.skills"
      :required="false"
      help="users.skills_help"
    >
      <AutoComplete
        v-if="skillsSelected.length < 5"
        v-model="searchTermSkill"
        :disabled="disabled"
        :suggestions="opensearchResults"
        :placeholder="$t('on_boarding.skill.search_placeholder')"
        @complete="updateSearchSkill"
        @item-select="selectSkill"
      >
        <template #empty>
          <div class="p-4 text-gray-500">
            {{ $t('on_boarding.skill.no_results') }}
          </div>
        </template>
        <template #option="slotProps">
          <div class="max-w-[250px] md:max-w-[750px] text-wrap">
            <div
              class="font-bold text-lg"
              v-html="slotProps.option.title"
            />
            <div
              class="text-base"
              v-html="slotProps.option.description"
            />
          </div>
        </template>
      </AutoComplete>
    </InputWrapper>
    <div
      v-if="!disabled"
      class="justify-start items-center gap-2 flex flex-col"
    >
      <Chip
        v-for="item in skillsSelected"
        :key="item.id"
        class="w-full flex items-center self-stretch bg-transparent"
        :label="item.title"
        icon="uil-frown"
        :removable="true"
        remove-icon="uil-times"
        @remove="removeSkill($event, item.id)"
      >
        <!--  "$emit('remove-from', {list: opensearch, id: item.id})" -->
        <div class="w-full flex item-center gap-4 items-center">
          <div class="w-8 h-8 flex items-center justify-center text-white p-2 bg-blue-100 rounded">
            <i class="uil uil-heart-sign text-xl text-blue-700" />
          </div>
          <div class="flex flex-col justify-center">
            <div class="font-bold text-gray-900">
              {{ item.title }}
            </div>
            <span
              class="text-gray-700 text-sm line-clamp-1"
            >
              {{ item.description }}
            </span>
          </div>
        </div>
      </Chip>
    </div>
  </div>
</template>

<script setup>

import InputWrapper from "@/components/common/InputWrapper.vue";
import AutoComplete from "primevue/autocomplete";
import Chip from "primevue/chip";
import {ref, defineProps, defineEmits} from "vue";
import {useJobSkills} from "@/composables/useJobSkills";

const props = defineProps({
  id: {type: String, required: true},
  lang: {type: String, default: 'it'},
  userSkills: {type: Array, default: () => []},
  userSkillNames: {type: Array, default: () => []},
  disabled: {type: Boolean, default: false},
})
const emit = defineEmits(['update-user-skills'])

const { searchSkills } = useJobSkills()

const minChars = 2
const searchTermSkill = ref('')
const skillsSelected = ref([])

const opensearchResults = ref([])

if (props.userSkills) {
  skillsSelected.value = props.userSkills.map((sId, idx) => {
    return {
      id: sId,
      title: props.userSkillNames[idx],
      // description: user.value.skill_descriptions[idx]  // TODO skill_descriptions
    }
  })
}

const updateSearchSkill = async () => {
  if (searchTermSkill.value.length < minChars) {
    opensearchResults.value = []
    return
  }
  searchSkills(searchTermSkill.value, props.lang).then((res) =>
    manageOpenSearchResult(res)
  )
}
const manageOpenSearchResult = (res) => {
  if (!res || res.error || res.length === 0) {
    opensearchResults.value = []
    return
  }
  opensearchResults.value = res.map((item) => {
    return {
      id: item['id'],
      title: getCleanedString(item.title),
      description: getCleanedString(item.description),
    }
  })
}
const getCleanedString = (string) => {
  return string.replace('|@|', '<br><small>').replace('|#|', '</small>')
}
const removeSkill = (event, id) => {
  const items = skillsSelected.value.filter((item) => item.id !== id)
  skillsSelected.value = items
  emit('update-user-skills', {userId: props.id, skills: skillsSelected.value})
}

const selectSkill = (element) => {
  if (skillsSelected.value.filter((item) => item.id === element.value.id).length === 0) {
    skillsSelected.value.push(element.value)
    emit('update-user-skills', {userId: props.id, skills: skillsSelected.value})
  }
  searchTermSkill.value = '';
  opensearchResults.value = [];
}


</script>

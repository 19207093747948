import { authorizedRequest } from "@/api";
import config from '../config';

// async function list(query = '', size = 20, lang = 'it') {
//   try {
//     const filterParam = `?query=${query}&lang=${lang}&size=${size}`;
//     const result = await request().get(`/jobs${filterParam}`)
//     return result.data.data;
//   } catch (error) {
//     return { error };
//   }
// }

async function list(q) {// size = 20, lang = 'it', query = ''
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/skills?q=${q}`)
    return result.data;
  } catch (error) {
    return { error };
  }
}

async function search(query, lang) {
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/skills/search/?lang=${lang}&q=${query.replaceAll("/"," ")}`);
    return result.data;
  } catch (error) {
    return { error }
  }
}

async function getById(id) {
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/skills/${id}`)
    // console.log(result);
    return result.data
  } catch (error) {
    return { error }
  }
}

export {
  list,
  search,
  getById
}

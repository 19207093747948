import {authorizedRequest, manageApiError, transformFilters} from "@/api";
import config from "@/config";


async function listPlans(limit= 20, offset= 0, paramsArgs=null) {
  // no pagination for now
  const filtersFormatted = transformFilters(paramsArgs.filters);
  const ordering = paramsArgs.sortField ? ((paramsArgs.sortOrder || 0) >= 0 ? '' : '-') + paramsArgs.sortField : '';
  const paginationParams = `limit=${limit}&offset=${offset}&ordering=${ordering}`; //&offset=-1 &limit=${limit}&offset=${offset}`;
  const params = filtersFormatted ? filtersFormatted+'&'+paginationParams : '?'+paginationParams;

  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/plans/${params}`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function createPlan(id, plan) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/plans/`,plan);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function updatePlan(id, plan) {
  try {
    const result = await authorizedRequest().patch(config.BACKEND_URL+`/api/v2/drf/plans/${plan.id}/`, plan);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function renewalPlan(fromPlanId, plan) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/plans/${fromPlanId}/renewal/`, plan);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}
async function deletePlanById(id) {
  try {
    const result = await authorizedRequest().delete(config.BACKEND_URL+`/api/v2/drf/plans/${id}/`);
    if (result.status === 204) {
      return { success: true };
    } else {
      return result.data;
    }
  } catch (error) {
    return manageApiError(error);
  }
}

async function listAddons(limit= 20, offset= 0, paramsArgs=null) {
  // no pagination for now
  const filtersFormatted = transformFilters(paramsArgs.filters);
  const ordering = paramsArgs.sortField ? ((paramsArgs.sortOrder || 0) >= 0 ? '' : '-') + paramsArgs.sortField : '';
  const paginationParams = `limit=${limit}&offset=${offset}&ordering=${ordering}`; //&offset=-1 &limit=${limit}&offset=${offset}`;
  const params = filtersFormatted ? filtersFormatted+'&'+paginationParams : '?'+paginationParams;

  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/addons/${params}`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}
async function listAddonsCatalogs() {
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/addons/catalogs/`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function createAddon(id, addon) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/addons/`,addon);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function updateAddon(id, addon) {
  try {
    const result = await authorizedRequest().patch(config.BACKEND_URL+`/api/v2/drf/addons/${addon.id}/`, addon);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function renewalAddon(fromAddonId, addon) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/addons/${fromAddonId}/renewal/`, addon);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}
async function deleteAddonById(id) {
  try {
    const result = await authorizedRequest().delete(config.BACKEND_URL+`/api/v2/drf/addons/${id}/`);
    if (result.status === 204) {
      return { success: true };
    } else {
      return result.data;
    }
  } catch (error) {
    return manageApiError(error);
  }
}
async function updateUsersToAddon(addonId, usersList) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/addons/${addonId}/update_users/`, {'users_list': usersList});
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}
async function removeUsersToAddon(addonId, usersList) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/addons/${addonId}/remove_users/`, {'users_list': usersList});
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}
async function setPayedPlanById(id) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/plans/${id}/set_payed/`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function createUserSubscription(planId, usersList) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/plans/${planId}/create_subscriptions/`, {'users_list': usersList});
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

export {
  listPlans,
  createPlan,
  updatePlan,
  deletePlanById,
  listAddons,
  createAddon,
  updateAddon,
  renewalAddon,
  deleteAddonById,
  listAddonsCatalogs,
  updateUsersToAddon,
  removeUsersToAddon,
  createUserSubscription,
  renewalPlan,
  setPayedPlanById,
};

import {
  list, search, getById
} from "@/api/jobSkills.js";
import { ErrorBus, ErrorCommands } from "@/eventBus";

function useJobSkills() {
  const searchJobSkills = async (query) => {
    const results = await list(query );
    if(!Array.isArray(results)){
      ErrorBus.emit(ErrorCommands.ERROR, { message: 'errors.general' });
      return {error: 'errors.general'}
    }
    if(results.error){
      ErrorBus.emit(ErrorCommands.ERROR, { message: results.error });
      return {error: 'errors.general'}
    }
    return results;
  };

  const searchSkills = async (query,  lang) => {
    const results = await search(query, lang );
    if(!Array.isArray(results)){
      ErrorBus.emit(ErrorCommands.ERROR, { message: 'errors.general' });
      return {error: 'errors.general'}
    }
    if(results.error){
      ErrorBus.emit(ErrorCommands.ERROR, { message: results.error });
      return {error: 'errors.general'}
    }
    return results;
  };

  const getJobSkillById = async (id) => {
    const results = await getById(id);

    if(results.error){
      ErrorBus.emit(ErrorCommands.ERROR, { message: results.error });
      return {error: 'errors.general'}
    }
    return results;
  };

  return {
    searchJobSkills,
    searchSkills,
    getJobSkillById
  }
}

export { useJobSkills };

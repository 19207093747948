import TrackedModel from "@/resources/TrackedModel";
import {useUserRoles} from "@/composables/userData";

export default class Addon extends TrackedModel {

  constructor(data) {
    super(data);

    const { userIsAdmin } = useUserRoles();
    this.organization = data.organization || "";
    this.status = data.status || "";
    this.provider = data.provider || null;
    this.catalog = data.catalog || "";
    this.catalog_display = data.catalog_display || "";
    this.catalog_obj = data.catalog_obj;
    this.service = data.catalog_obj?.service;
    this.organization_name = data.organization_name || "";
    this.organization_info = data.organization_info || {};
    this.total_license = data.total_license || 0;
    this.used_license = data.used_license || 0;
    this.used_on_total_license = this.used_license.toString() + ' / ' + this.total_license.toString();
    this.start_date = data.start_date ? TrackedModel.asDate(data.start_date) : null;
    this.expiration_date = data.expiration_date ? TrackedModel.asDate(data.expiration_date) : null;
    this.price = data.price || 0;
    this.rel_users_ids = data.rel_users_ids || [];

    // const today = TrackedModel.asInputDate(new Date());
    this.is_expired = data.status === 'expired'; // data.expiration_date < today;
    this.permissions.edit = userIsAdmin();
    this.permissions.delete = data.status === 'not_started' && userIsAdmin();
    this.permissions.select = true;
    this.permissions.renewal = userIsAdmin() && ['not_started', 'active', 'expiring'].indexOf(this.status) > -1;

    this.disabled = this.is_expired;

    return this;
  }

  static getValidListOptions(records, fieldName, defaultEmptyLabel, addEmptyItem=true){
    let forListBoxOption = [];
    if (addEmptyItem) {
      forListBoxOption.push({key: 0, label: defaultEmptyLabel, subscription: 0});
    }
    forListBoxOption = forListBoxOption.concat(
      records.filter(addon => !addon.is_expired && addon.total_license > addon.used_license)
             .map(record => { return record.convertForListBoxOption();})
    );

    return forListBoxOption;
  }



  convertForListBoxOption() {
    return {
      key: this.id,
      label: this.catalog_display + '( Used ' + this.used_license + '/' + this.total_license + ')',
      catalog: this.catalog
    };
  }


  static getValidListOptionsForUser(records){
    return records.map(record => {
      return record.convertForListBoxOptionForUser();
    });
  }

  convertForListBoxOptionForUser() {
      return {
        key: this.id,
        label: this.catalog_display,
        catalog: this.catalog,
        addon_id: this.id,
        total_license: this.total_license,
        used_license: this.used_license,
        start_date: TrackedModel.asOutputDate(this.start_date, 'it'),
        expiration_date: TrackedModel.asOutputDate(this.expiration_date, 'it'),
        disabled: this.total_license <= this.used_license
    };
  }


  static fromApiResult(addonData) {
    const addonObj = new Addon(addonData);
    return addonObj;
  }

  static forApi(recordObj) {
    let recordData = {};
    const dateFields = ['start_date', 'expiration_date'];
    Object.getOwnPropertyNames(recordObj).forEach(key => {
      if (dateFields.indexOf(key) > -1 && recordObj[key]) {
        recordData[key] = TrackedModel.asInputDate(recordObj[key]);
      } else {
        recordData[key] = recordObj[key];
      }
    });
    recordData.catalog = recordObj.catalog || recordObj.catalog_obj?.key;
    return recordData;
  }

  thisForApi() {
    return Addon.forApi(this);
  }

}

<template>
  <div class="w-full h-full bg-gray-100 min-h-[100dvh] pt-20 md:pt-16 px-4 sm:px-6 gap-6">
    <div class="flex flex-col items-center gap-6 bg-white border-gray-200 rounded-2xl sm:w-[45rem] mx-auto py-8 px-6 text-center">
      <div class="flex justify-center items-center bg-blue-100 w-12 h-12 rounded-lg">
        <i class="uil uil-check text-blue-700 text-3xl" />
      </div>
      <div>
        <h3> {{ $t(`user_profile.landing.${landingTarget}.finish_title`) }} </h3>
        <h5> {{ $t(`user_profile.landing.${landingTarget}.finish_subtitle`) }} </h5>
      </div>
      <p>
        {{ $t(`user_profile.landing.${landingTarget}.finish_desc`) }}
        <br>
        {{ $t(`user_profile.landing.${landingTarget}.finish_desc2`) }}
      </p>
      <Button
        :label="$t(`user_profile.landing.${landingTarget}.finish_back`)"
        @click="toHome"
      />
    </div>
  </div>
</template>

<script setup>

import { useRouter } from "vue-router";
import { defineProps } from 'vue'

// import useRedirectUnauthorized from "@/composables/useRedirectUnauthorized";

import Button from "primevue/button";

// name: "Landing",
// useRedirectUnauthorized();
const router = useRouter();

defineProps({
  landingTarget: {
    type: String,
    default: 'interview',
  }
});

const toHome = () => router.push({ name: "ForYou" });
</script>

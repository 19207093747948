<template>
  <div class="flex items-center w-full gap-2">
    <div class="w-10 h-10 flex items-center justify-center text-blue-700 p-2 bg-blue-100 rounded">
      <i class="uil uil-building text-xl" />
    </div>
    <div class="text-gray-900 truncate">
      {{ organizationName }}
    </div>
    <div
      v-if="userIsAdmin() || userIsAnyAdmin()"
      data-test="organization-modal"
      class="flex-grow flex justify-end items-center gap-2"
      @click="organizationModal"
    >
      <Button
        severity="interactive"
        icon="uil uil-pen"
      />
    </div>
    <Dialog
      v-model:visible="showSmallOrganizationDialog"
      class="m-4"
      :modal="true"
      :draggable="false"
    >
      <template #header>
        <div>
          <h5 class="hidden sm:flex">
            {{ $t('organizations.small_title') }}
          </h5>
          <h6 class="flex sm:hidden">
            {{ $t('organizations.small_title') }}
          </h6>
          <div class="text-base">
            {{ $t('organizations.small_subtitle') }}
          </div>
        </div>
      </template>
      <div class="flex flex-col gap-4">
        <OrganizationForm
          :id="currentUser.organization_id"
          :record="currentUser.organization"
          :small="true"
          class="organization-form"
          @refreshName="refreshName"
          @closeModal="closeDialog"
        />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import {useUserData, useUserRoles} from "@/composables/userData";
import OrganizationForm from "@/components/management/OrganizationForm.vue";
import {ref} from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";

const { getUser } = useUserData()
const { userIsAdmin, userIsAnyAdmin } = useUserRoles();
const currentUser = getUser()
const organizationName = ref('');
if (!organizationName.value) {
  organizationName.value = currentUser.organization_info.name;
}
const showSmallOrganizationDialog = ref(false)

const closeDialog = () => {
  showSmallOrganizationDialog.value = false
}
const organizationModal = () => {
  showSmallOrganizationDialog.value = true
}
const refreshName = (newName) => {
  organizationName.value = newName;
}
</script>

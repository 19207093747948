import {useUserData} from "@/composables/userData";
import TrackedModel from "@/resources/TrackedModel";

export const PATCH_FIELDS = [
  'id',
  'title',
  'description',
  'filters',
  'resources'
];

export default class Collection extends TrackedModel {

  constructor(data) {
    super(data);
    this.id = data.id || "NO_ID"; // new UUID()
    this.title = data.title || "";
    this.description = data.description || "";
    this.userId = data.user || "NO_UUID";
    this.userFullName = data.user_fullname || "";
    this.resources_full = data.resources_full || [];
    this.creator = data.creator;
    this.skillName = data.skillName;
    this.occupationName = data.occupationName;
    this.share = data.share;
    this.sharedWith = data.shared_with || [];
    this.shareLevel = data.share_level || '';
    // this.image = data.image || {
    this.image = {
      src: require(`@/assets/collection-placeholder.svg`),
      width: 512,
      height: 512,
      is_placeholder: true
    };
    this.creator = data.creator || "--";

    this.resources_images = this.resources_full.map((content) => content.image) || [];

    this.processTitle();
    return this;
  }
  processTitle() {
    if(this.isSystemCollection && this.title.indexOf('|') > -1) {
      const parts = this.title.split('|');
      this.source = parts[0]; // occupation|skill
      if (this.source === 'occupation' || this.source === 'suggestion') {
        console.log(parts[1])
        if(parts[1] == 'created_for_you'|| parts[1] == 'suggested_for_you'){
          this.description = 'collections.system_'+ parts[1] +'_desc'
        }
        this.classification = parts[1];
        this.occupationId = parts.length > 2 ? parts[2] : '';
        this.occupationName = this.description;
        this.systemTitle = null;
        this.systemType = 'occupation' + (this.classification ? '_'+this.classification : '');
      } else if (this.source === 'interview') {
        this.classification = parts[0];
        this.systemTitle = parts[1];
        this.systemType = 'interview';
      } else {
        this.classification = 'skill';
        this.skillId = parts[1];
        this.skillName = this.description;
        this.systemTitle = this.skillName;
        this.description = 'collections.system_skill_desc';
        this.systemType = 'skill';
      }
    } else if( this.share === 'SHARE_GLOBAL'){
      this.source = 'shared'
      this.classification = 'global';
      this.systemType = 'global';
    } else if( this.share === 'SHARE_ORG'){
      this.source = 'shared'
      this.classification = 'organization';
      this.systemType = 'from_organization';
    } else if( this.share === 'SHARE_GROUP' && !this.isMine()){
      this.source = 'shared'
      this.classification = 'group';
      this.systemType = 'from_group';
    } else {
      this.source = 'personal'
      this.classification = 'mine'
    }
  }

  isSystemCollection() {
    return this.creator === 'SYSTEM';
  }
  isSharedWithUser() {
    const { getUser } = useUserData();
    const user = getUser().id;
    return (this.creator === 'USER' && user !== this.userId);
  }
  isEmpty(){
    return this.resources_full.length === 0;
  }
  isShared(){
    const { getUser } = useUserData();
    const user = getUser().id;
    return (this.share !=='SHARE_NONE' && user === this.userId);
  }
  isMine(){
    const { getUser } = useUserData();
    const user = getUser().id;
    return this.id !== 'NO_ID' && user === this.userId;
  }

  static getDataForPatchOperation(data){
    const newObj = {};
    for(const i in data) {
      if(PATCH_FIELDS.includes(i)) {
        if(i === 'image' && this.image.is_placeholder) { continue; }
        if(i === 'resources') { newObj[i] = data[i].map(res => {
          return ((typeof res) === 'object') ? res.id : res;
        }); }
        else {
          newObj[i] = data[i];
        }
      }
    }
    return newObj;
  }


  static fromApiResult(coll) {
    const collection = new Collection(coll);

    return collection;
  }
}

<template>
  <div class="flex flex-col gap-4">
    <div class="logo-container p-2 w-10">
      <div class="logo-small" />
    </div>
    <p>
      {{ $t('agreement.text', {name: currentUser.organization_info.name}) }}
      <br>
      {{ $t('agreement.text_2', {name: currentUser.organization_info.name}) }}
    </p>
    <div class="flex flex-col items-center gap-4">
      <Button
        class="w-56"
        :label="$t('agreement.button-ag')"
        @click="switchAgreement(true)"
      />
      <Button
        class="w-56"
        severity="secondary"
        link
        :label="$t('agreement.button-not')"
        @click="switchAgreement(false)"
      />
    </div>
    <p class="text-gray-700 text-xs font-normal leading-tight">
      {{ $t('agreement.undertext') }}
    </p>
  </div>
</template>
<script setup>
import Button from 'primevue/button';

import { useUserData } from '@/composables/userData';
import {defineEmits} from "vue";

// name: "ModalAskForAgreement",
const emit = defineEmits(['close'])

const { getUser, callSetAgreement  } = useUserData();
const currentUser = getUser()
const closeModal = () => {
    emit('close')
}

const switchAgreement = (value) => {
    callSetAgreement(currentUser.id, value)
    closeModal()
}

</script>


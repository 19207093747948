import TrackedModel from "@/resources/TrackedModel";
import {useUserRoles} from "@/composables/userData";
import User from "@/resources/User";

export default class Group extends TrackedModel {

  constructor(data) {
    super(data);

    const { userId, userIsAnyAdmin, userIsManager } = useUserRoles();
    this.name = data.name || "";
    this.parent = data.parent || null;
    this.assigned_to = data.assigned_to || "";
    this.assigned_to_full_name = data.assigned_to_full_name || "";
    this.assigned_to_email = data.assigned_to_email || "";
    this.organization = data.organization || "";
    this.organization_name = data.organization_name || "";
    this.description = data.description || "";
    this.users_counter = data.users_counter || 0;
    this.share_coll_counter = data.share_coll_counter || 0;
    this.share_coll_info = data.share_coll_info || 0;
    this.rel_users_ids = data.rel_users_ids || [];

    this.permissions.edit = userIsAnyAdmin() || (userIsManager() && userId() === this.assigned_to);
    this.permissions.delete = userIsAnyAdmin() || (userIsManager() && userId() === this.assigned_to);
    this.permissions.select = userIsAnyAdmin() || (userIsManager() && userId() === this.assigned_to);
    this.permissions.open = userIsAnyAdmin() || (userIsManager());
    this.rel_users = [];

    let users = data.rel_users;
    if( users ) {
      this.rel_users = users.map(user => {
        let userObj = User.fromApiResult(user);
        userObj.permissions.edit = false;
        // userObj.permissions.delete = this.assigned_to !== userObj.id;
        return userObj;
      });
    }


    return this;
  }



  static fromApiResult(data) {
    const obj = new Group(data);
    return obj;
  }


  convertForListBoxOption() {
    return {
      key: this.id.toString(),
      label: this.name,
      users_counter: this.users_counter
    };
  }
}

<template>
  <div v-if="!isLoadingInterview">
    <h5 v-if="!onModal">
      {{ $t(header) }}
    </h5>
    <div
      v-if="canStart"
      class="flex flex-col gap-4"
      :class="{'w-1/2 mx-auto mt-20': !onModal}"
    >
      <div class="text-base">
        {{ $t('interview.modal_body_first') }}
      </div>
      <div class="text-base">
        {{ $t('interview.modal_body_second') }}
      </div>
      <div class="text-base">
        {{ $t('interview.modal_body_third') }}
      </div>
      <div class="flex align-items-center">
        <Checkbox
          v-model="privacyChecked"
          input-id="privacy-check"
          binary
          :invalid="formInvalid"
          @change="formInvalid = false"
        />
        <label
          for="privacy-check"
          v-html="$t('interview.modal_accept_privacy')"
        />
      </div>
      <div
        v-if="formInvalid"
        class="text-red-500 text-sm mt-[-0.5rem]"
      >
        {{ $t('interview.modal_accept_privacy_error') }}
      </div>
      <div class="flex flex-col-reverse sm:flex-row justify-end gap-2">
        <Button
          type="button"
          :label="$t('interview.modal_cancel')"
          severity="tertiary"
          @click="close()"
        />
        <Button
          type="button"
          class="text-base sm:text-lg"
          :label="$t(saveLabel)"
          @click="submitForm"
        />
      </div>
    </div>
    <InterviewStatusSection v-else />
  </div>
</template>

<script setup>

import {computed, defineEmits, defineProps, ref} from 'vue'
import useRedirectUnauthorized from '@/composables/useRedirectUnauthorized'
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import {useInterview} from "@/composables/useInterview";
import InterviewStatusSection from "@/components/InterviewStatusSection.vue";


const emit = defineEmits(['close'])
const close = () => {
  emit('close')
}

// name: 'StartInterview',
const canStart = ref(true)
useRedirectUnauthorized()

const { isLoadingInterview, startInterview, interview } = useInterview()
if(['COMPLETED', 'PROCESSED', 'REPORT_READY'].indexOf(interview.value.status) > -1) {
  canStart.value = false;
}

const privacyChecked = ref(false);
const formInvalid = ref(false);

const props = defineProps({
  mode: {
    type: String,
    required: true,
    default: 'initialize',
  },
  onModal: {
    type: Boolean,
    default: false,
  }
});

const saveLabel = computed(() => {
  return props.mode === 'initialize' ? 'interview.modal_save_label_initialize' : 'interview.modal_save_label_continue';
});
const header = computed(() => {
  return props.mode === 'initialize' ? 'interview.modal_header_initialize' : 'interview.modal_header_continue';
});


const isApple = ref(false)
let UA = navigator.userAgent
let isSafari =
  /\b(iPad|iPhone|iPod|Safari)\b/.test(UA) &&
  /WebKit/.test(UA) &&
  /Safari/.test(UA) &&
  /like Gecko/.test(UA) &&
  !/Edge/.test(UA) &&
  !/Chrome/.test(UA) &&
  !/Chromium/.test(UA) &&
  !window.MSStream
// console.debug(`isApple=${this.isApple} isSafari=${isSafari}`)
if (isSafari === true) {
  isApple.value = true
  console.debug('Disabling Apple Safari')
}

const submitForm = async () => {
  if (!privacyChecked.value) {
    formInvalid.value = true;
    return;
  } else {
    await startInterview().then((result) => {
      if (isApple.value) {
        window.location.href = result.url
      } else {
        window.open(result.url, '_blank');
      }
      close()
    })
  }
}

</script>

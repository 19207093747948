<template>
  <div class="organization-form">
    <form
      data-test="create-organization"
      @submit.prevent="submitToApi"
    >
      <div v-if="small">
        <div class="flex gap-6 flex-col">
          <FormInput
            v-model.trim="organization.name"
            name="name"
            type="text"
            label="organizations.small_name"
            min-value="6"
            :disabled="loading"
            required
          />
          <div class="flex flex-col-reverse gap-4 justify-end md:flex-row md:gap-6">
            <Button
              :label="$t('general.cancel')"
              severity="tertiary"
              @click.prevent="cancel"
            />
            <Button
              data-test="submit"
              :label="$t('general.save')"
              :loading="loading"
              @click="submitToApi"
            />
          </div>
          <!-- :disabled="!canSubmit" -->
        </div>
      </div>
      <div v-else>
        <span v-if="billing">{{ $t('organizations.form.company_info.billing_subtitle') }}</span>
        <div class="form-body form-group">
          <p
            v-if="errorMsg"
            class="error"
          >
            {{ errorMsg }}
          </p>
          <div class="form-group-fields">
            <h5 v-if="!billing">
              {{ $t('organizations.form.general.title') }}
            </h5>
            <div class="cap_city">
              <FormInput
                v-model.trim="organization.name"
                name="name"
                type="text"
                label="organizations.name"
                min-value="6"
                required
              />
              <FormInput
                v-model="organization.email"
                name="email"
                type="email"
                :label="$t('organizations.form.general.email')"
              />
            </div>
            <h5 v-if="!billing">
              {{ $t('organizations.form.company_info.title') }}
            </h5>
            <FormInput
              v-model="organization.address"
              name="address"
              type="text"
              :required="billing"
              :invalid="!(billing && !!organization.address) && showError"
              :label="$t('organizations.form.company_info.address')"
            />
            <div class="cap_city">
              <FormInput
                v-model="organization.address_detail"
                class="address_detail"
                name="address_detail"
                type="text"
                :label="$t('organizations.form.company_info.address_detail') + $t('general.optional')"
              />
            </div>
            <div class="cap_city">
              <FormInput
                v-model="organization.city"
                class="city"
                name="city"
                type="text"
                :required="billing"
                :invalid="!(billing && !!organization.city) && showError"
                :label="$t('general.city')"
              />
              <FormInput
                v-model="organization.zip_code"
                class="zip_code"
                name="zip_code"
                type="text"
                :required="billing"
                :invalid="!(billing && !!organization.zip_code) && showError"
                :label="$t('general.zipcode')"
              />
            </div>
            <div class="cap_city">
              <FormInput
                v-model="organization.province"
                class="province"
                name="province"
                type="text"
                :required="billing"
                :invalid="!(billing && !!organization.province) && showError"
                :label="$t('general.province')"
              />
              <FormInput
                v-model="organization.country"
                name="country"
                type="text"
                :required="billing"
                :invalid="!(billing && !!organization.country && organization.country.length === 2) && showError"
                :label="$t('general.country')"
                :help="$t('organizations.form.company_info.country_help')"
              />
            </div>
            <!-- https://www.npmjs.com/package/vue-country-code-select -->
            <div class="cap_city">
              <FormInput
                v-model="organization.cf_code"
                name="cf_code"
                type="text"
                :required="billing"
                :invalid="!(billing && !!organization.cf_code) && showError"
                :label="$t('organizations.form.company_info.cf_code')"
              />
              <FormInput
                v-model="organization.vat_code"
                name="vat_code"
                type="text"
                :label="$t('organizations.form.company_info.vat_code')"
                :help="$t('organizations.form.company_info.vat_code_help')"
              />
            </div>
            <div class="cap_city">
              <FormInput
                v-model="organization.phone"
                name="phone"
                type="text"
                :label="$t('general.phone') + $t('general.optional')"
              />
            </div>
            <FormInput
              v-if="userIsAdmin()"
              id="source"
              v-model="organization.source"
              name="source"
              type="select"
              label="organizations.form.company_info.source"
              field="source"
              :options="sourceOptions"
            />
          </div>
          <div v-if="userIsAdmin() && organization.id === 'NO_ID'">
            <div class="p-section--label">
              {{ $t('organizations.form.company_info.plan') }}
            </div>
            <FormInput
              v-model="organization.pay_in_app"
              name="pay_in_app"
              type="switch"
              label-position="right"
              :label="$t('organizations.form.company_info.pay_in_app')"
            />
            <FormInput
              v-model="organization.trial"
              name="trial"
              type="switch"
              label-position="right"
              :label="$t('organizations.form.company_info.trial')"
              :disabled="!organization.pay_in_app"
            />
            <FormInput
              v-model="organization.trial_days"
              name="trial_days"
              type="number"
              :label="$t('organizations.form.company_info.trial_days')"
              :disabled="!organization.pay_in_app"
            />
          </div>
          <div v-if="userIsAdmin() && organization.id !== 'NO_ID'">
            <h5> INFO for admin </h5>
            <div
              v-if="loadedUsers"
              class="form-admin-row"
            >
              <FormInput
                v-model="organization.referent"
                name="referent"
                type="select"
                field="referent"
                label="organizations.form.referent"
                :options="organizationUsers"
              />
            </div>
            <div
              v-if="userIsAdmin()"
              class="form-admin-row"
            >
              <FormInput
                id="pay_in_app"
                v-model="organization.pay_in_app"
                name="pay_in_app"
                type="switch"
                label-position="right"
                label="organizations.form.pay_in_app"
                field="pay_in_app"
                :disabled="true"
              />
              <FormInput
                id="personal"
                v-model="organization.personal"
                name="personal"
                type="switch"
                label-position="right"
                label="organizations.form.personal"
                field="personal"
                :disabled="true"
              />
            </div>
            <div
              v-if="userIsAdmin()"
              class="form-admin-row"
            >
              <div class="components-form-input">
                <label class="label">{{ $t('organizations.form.stripe_customer_id') }}: </label>
                <div> {{ organization.stripe_customer_id }}</div>
              </div>
              <div class="components-form-input">
                <label class="label">{{ $t('organizations.form.payment_attached_date') }}: </label>
                <div> {{ organization.payment_attached_date }}</div>
              </div>
            </div>
          </div>
          <!--              <FormInput-->
          <!--                v-if="userIsAdmin()"-->
          <!--                id="is_demo"-->
          <!--                v-model="organization.is_demo"-->
          <!--                name="is_demo"-->
          <!--                type="checkbox"-->
          <!--                label="users.is_demo"-->
          <!--                field="is_demo"-->
          <!--              />-->
          <div class="form-footer">
            <div class="form-controls">
              <Button
                class="p-button-rounded white-space-nowrap"
                :label="$t('general.cancel')"
                severity="tertiary"
                @click.prevent="cancel"
              />
              <Button
                class="p-button-rounded white-space-nowrap"
                :label="$t('general.save')"
                :loading="loading"
                @click="submitToApi"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { ref } from 'vue'
import FormInput from '@/components/FormInput.vue'
import { useOrganizations } from '@/composables/useOrganizations'
import { useToasts } from "@/composables/useToasts";
import Organization from "@/resources/Organization";
import Button from 'primevue/button';
import {useUserRoles} from "@/composables/userData";
import User from "@/resources/User";
import {ROLES} from "@/api/user";
import {useI18n} from "vue-i18n";

export default {
  name: 'OrganizationForm',
  components: {
    FormInput,
    Button
  },
  props: {
    id: { type: String, required: false, default: null },
    record: { type: Object, default: null,},
    small: { type: Boolean, default: false},
    billing: { type: Boolean, default: false},
  },
  // async beforeRouteUpdate(to, from) {
  //   // react to route changes...
  //   console.log('beforeRouteUpdate orgform',to, from )
  //   // this.userData = await fetchUser(to.params.id)
  // },
  setup(props, {emit}) {
    const loading = ref(props.id ? true : false)
    const editMode = ref(!!props.id)
    const canSubmit = ref(true) // disabled in template
    const currentOrganization = new Organization({id:null, status: 'ACTIVE'});
    const organization = ref(currentOrganization);
    const organizationUsers = ref([{key:'', label:''}])
    const originalValues = ref({})
    const invariantName = ref('')
    const errorMsg = ref('')
    const { createOrganization, updateOrganization, getUsersList } = useOrganizations()
    const { toastSuccess, toastError } = useToasts();
    const { userIsAdmin } = useUserRoles();
    // const { userIsAdmin, userIsAnyAdmin, userIsManager } = useUserRoles();
    const sourceOptions = [
      {key: 'Direct', label: 'Direct'},
      {key: 'Demo', label: 'Demo'},
      {key: 'Signup', label: 'Signup'},
    ]

    const setCurrentOrganization = (org) => {
      currentOrganization.value = org
      organization.value = currentOrganization.value
      originalValues.value = currentOrganization.value
      invariantName.value = currentOrganization.value.name
      loading.value = false
    };

    emit("update:selectedOrganization", props.id);

    const { t } = useI18n();
    const loadedUsers = ref(false);
    const loadUsersOptions = (orgId) => {
      const userFilters = [{
        'field': 'organization',
        'value': orgId || props.id
      }, {
        'field': 'role__in',
        'value': [ROLES.ADMIN, ROLES.SUPERVISOR]
      }, {
        'field': 'for_choice',
        'value': true
      }]
      if (orgId || props.id) {
        getUsersList(-1, 0, {'filters': userFilters})
          .then((users) => {
            loadedUsers.value = true;
            if (users.error) {
              return []
            }
            organizationUsers.value = User.getValidListOptions(users.results, 'name', t('general.select'));
          });
      }
    }
    loadUsersOptions()
    // context.on("update:selectedOrganization", loadUsersOptions)
    const afterSave = (result) => {
      loading.value = false;
      if (result.error) {
        errorMsg.value = result.error.toString()
        toastError('Error', errorMsg.value.toString())
      } else {
        organization.value = result;
        toastSuccess('', editMode.value ? 'organizations.updated':'organizations.created', {
          translation:{
            organization: organization.value.name
          }
        })
        // router.push({ name: 'organizations' })

        emit('refreshName', organization.value.name)
        emit('refresh', organization.value)
        emit('closeModal')
        if (props.billing) {
          emit('refreshForBilling', organization.value)
        }
      }
    }

    const requiredFields = [
      'name',
      'address',
      'city',
      'zip_code',
      'country',
      'province',
      'cf_code'
    ]
    const showError = ref(false);
    const validateFields = () => {
      let validate = true
      if (props.billing) {
        let checkFields = requiredFields.filter(field => !!organization.value[field])
        if (checkFields.length < requiredFields.length ){
          validate = false
          showError.value = true
        }
        if (organization.value['country'].length > 2) {
          validate = false
          showError.value = true
        }
      }
      return validate
    }

    const submitToApi = () => {
      if(!canSubmit.value || !validateFields()) {
        return;
      }
      loading.value = true;
      if (editMode.value) {
        let dataPatch = organization.value
        if(props.small) {
          dataPatch = {id: organization.value.id, name: organization.value.name}
        }

        updateOrganization(dataPatch).then(
          result => {
            afterSave(result)
          });
      } else {
        createOrganization(organization.value).then(
          result => {
            afterSave(result)
          });
      }
    }

    // const { jobRoles } = useJobRoles()
    const cancel = () => {
      emit('closeModal')
    }

    return {
      // jobRoles,
      cancel,
      canSubmit,
      editMode,
      errorMsg,
      setCurrentOrganization,
      loadUsersOptions,
      loadedUsers,
      currentOrganization,
      originalValues,
      invariantName,
      loading,
      showError,
      organization,
      submitToApi,
      userIsAdmin,
      sourceOptions,
      organizationUsers,
    }
  },
  created() {
    // this.$watch(
    //   () => this.$route.params,
    //   (toParams, previousParams) => {
    //     // react to route changes...
    //   }
    // )

    const { getOrganizationById } = useOrganizations()

    this.loadedUsers = false;
    if (this.id && !this.record) {
      getOrganizationById(this.id).then(org => {
        this.setCurrentOrganization(org)
        this.loadUsersOptions(this.id)
      })
    } else if (this.record) {
      this.setCurrentOrganization(new Organization(this.record))
      this.loadUsersOptions(this.record.id)
    }
  }
}
</script>
<style scoped>
.small-title {
  color: #000;

/* Highlight text/Bold */
font-family: "Nunito Sans";
font-size: 1.375rem;
font-style: normal;
font-weight: 700;
line-height: 2rem; /* 145.455% */
}
.small-subtitle {
  color: #000;
font-feature-settings: 'calt' off;

/* Paragraph/Normal/Regular */
font-family: "Nunito Sans";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Phone */
@media (max-width: 500px) {
  .form-header {
    display: grid;
  }
  .form-header .form-controls {
    width: 50%;
  }
}
/*.form-group {*/
/*  display: grid;*/
/*  width: inherit;*/
/*  grid-template-columns:*/
/*    [help-start] 0.3fr*/
/*    [help-end fields-start] 1fr*/
/*    [fields-end]*/
/*  ;*/
/*  border-top: 1px solid;*/
/*  border-color: var(--border-light-grey);*/
/*  padding: 3rem 1rem;*/
/*}*/
.form-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-self: stretch;
}
/*.form-group-fields {*/
/*  flex-grow: 1;*/
/*  grid-column-start: fields-start;*/
/*  grid-column-end: fields-end;*/
/*}*/
.form-group-fields > * {
  padding: 0.75rem;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
.cap_city {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
}
/*.cap_city .city {*/
/*  flex-grow: 1;*/
/*  margin-left: 10px;*/
/*}*/
.form-admin-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 1rem;
}
.form-admin-row > div {
  width: 50%
}
.label {
  gap: 0.25rem;
  color: var(--Text-default, #121520);
  /* text-sm/bold */
  font-family: var(--font-text);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; /* 133.333% */
}
</style>

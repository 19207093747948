<template>
  <div
    class="fixed w-[300px] h-full px-4 py-6 bg-white border-r [border-right-style:solid] border-gray-200 flex-col justify-between flex z-10"
  >
    <div
      class="self-stretch md:h-[calc(100%-100px)] flex-col justify-start items-start gap-8 flex"
      :class="{
        'h-[calc(100%-250px)]': currentUser?.addon_catalogs && currentUser?.addon_catalogs.indexOf('career_coach') > -1
      }"
    >
      <OrganizationItem v-if="isAuthenticated" />
      <div
        class="self-stretch flex-col justify-start items-start gap-1 flex overflow-auto"
      >
        <PanelMenu
          :model="items"
          class="w-full"
        >
          <template #item="{ item }">
            <a
              :href="item.url"
              :target="item.target"
              class="no-underline"
            >
              <span :class="item.icon" />
              <span
                :class="{
                  'font-bold': !item.subItem
                }"
                class="text-base ml-2"
              >
                {{ $t(item.label) }}
                <span
                  v-if="item.desktopOnly"
                  class="text-gray-500 text-xs font-bold flex md:hidden"
                >
                  {{ $t('general.desktop_only') }}
                </span>
              </span>
              <span
                v-if="item.items"
                class="uil uil-angle-down text-primary ml-auto"
              />
            </a>
          </template>
        </PanelMenu>
      </div>
    </div>
    <!-- FOOTER -->
    <div class="flex-col justify-start items-start gap-4 flex bg-white h-max shrink-0">
      <Alert
        v-if="
          isAuthenticated &&
            userIsAnyAdmin() &&
            currentUser.organization_info.pay_in_app &&
            !currentUser.organization_info.payment_attached_date &&
            !isMobile
        "
        :title="
          $t('sidebar.missing_payment_title', { count: planData.totalDays })
        "
        :type="planData.trialWarning ? 'warning' : 'info'"
      >
        <template #content>
          <p class="text-xs">
            {{ $t('sidebar.missing_payment_text') }}
          </p>
        </template>
      </Alert>
      <CareerCoachBanner v-if="currentUser?.addon_catalogs && currentUser?.addon_catalogs.indexOf('career_coach') > -1" />
      <!-- line-divider -->
      <div class="self-stretch h-px bg-gray-200" />
      <div class="gap-2 hidden md:flex">
        <div class="text-gray-900 text-sm leading-tight">
          Powered by
        </div>
        <img
          class="h-4"
          src="../../assets/logo_viblio_transparent_short_blue.svg"
          alt="logo viblio"
        >
      </div>
      <div class="flex flex-col md:hidden gap-2">
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toSupport"
        >
          <i class="uil uil-envelope text-gray-500" />
          <div class="text-sm">
            {{ $t('support.nav_bar_text') }}
          </div>
        </div>
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toProfile"
        >
          <i class="uil uil-user text-gray-500" />
          <div class="text-sm">
            {{ $t('user.profile') }}
          </div>
        </div>
        <a
          data-test="logout"
          class="flex gap-2 cursor-pointer"
          @click="logoutAndRedirect"
        >
          <div class="text-sm">
            {{ $t('user.logout') }}
          </div>
        </a>
      </div>
    </div>
    <Dialog
      v-model:visible="showProfileDialog"
      class="w-full sm:w-[1040px] m-4"
      modal
      :draggable="false"
      :header="$t('user.profile')"
    >
      <Profile
        @closeDialog="
          () => {
            showProfileDialog = false
          }
        "
      />
    </Dialog>
    <Dialog
      v-model:visible="showSupportDialog"
      class="m-4"
      :header="$t('support.title')"
      modal
      :draggable="false"
    >
      <Support
        @closeDialog="
          () => {
            showSupportDialog = false
          }
        "
      />
    </Dialog>
  </div>
</template>
<script setup>
import { computed, defineEmits, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useWindowSize } from '@vueuse/core'
import { useUserData, useUserRoles } from '@/composables/userData'

import Dialog from 'primevue/dialog'
import PanelMenu from 'primevue/panelmenu'

import Alert from '@/components/Alert.vue'
import Profile from '@/components/Profile.vue'
import CareerCoachBanner from '@/components/sidebar/CareerCoachBanner.vue'
import OrganizationItem from '@/components/sidebar/OrganizationItem.vue'
import Support from '@/components/Support.vue'

const { isAuthenticated, logoutUser, getUser } = useUserData()
const { userOrganizationId, userIsMember, userIsManager, userIsAdmin, userIsAnyAdmin } = useUserRoles()

const router = useRouter()
const currentUser = getUser()
const route = useRoute()
const currentRoute = computed(() => router.currentRoute.value)
const currentOrganizationId = computed(() => {
    return ['organization-plans', 'organization-users', 'organization-groups'].indexOf(route?.name) > -1 ? route?.params?.id : userOrganizationId()
  }
)

const isItemActive = (itemRoutes) => {
  return itemRoutes.includes(currentRoute.value?.name)
}

const managerManageItem =
  {
    label: 'sidebar.groups',
    subItem: true,
    active: isItemActive(['organization-groups']),
    command: () =>
      navigate({
        name: 'organization-groups',
        params: { id: currentOrganizationId.value },
      }),
  }

const manageItems = [
  {
    label: 'sidebar.plans',
    subItem: true,
    active: isItemActive(['organization-plans']),
    command: () =>
      navigate({
        name: 'organization-plans',
        params: { id: currentOrganizationId.value }
      }),
  },
  {
    label: 'sidebar.users',
    subItem: true,
    active: isItemActive(['organization-users']),
    command: () =>
      navigate({
        name: 'organization-users',
        params: { id: currentOrganizationId.value },
      }),
  },
  managerManageItem
]

console.log('manageItems', manageItems)
// const orgItem = [{
//   key: 'organizations',
//   label: 'sidebar.organizations',
//   active: isItemActive(['organizations']),
//   items: manageItems,
//   command: () =>
//     navigate({
//       name: 'organizations',
//       params: { id: userOrganizationId() },
//     }),
// }]
// const expandedKeys = ref()
// if (userIsAdmin()) {
//   expandedKeys.value = {'manage': true, 'organizations': true}
// }


const emit = defineEmits(['close'])
const { width } = useWindowSize()
const isMobile = computed(() => width.value < 600)

const navigate = (route) => {
  router.push(route)
  emit('close')
}

const items = ref([]);
items.value = [
  {
    label: 'sidebar.for_you',
    icon: 'uil-bookmark',
    // disabled: true,
    active: isItemActive(['ForYou']),
    command: () => {navigate({ name: 'ForYou' })}
  }, {
    label: 'sidebar.collections',
    icon: 'uil-books',
    items: [{
      label: 'sidebar.collections_personal',
      subItem: true,
      active:
        currentRoute.value?.name === 'collections-list' &&
        currentRoute.value.params?.collectionType === 'personal',
      command: () =>
        navigate({
          name: 'collections-list',
          params: {collectionType: 'personal'}
        }),
    },
      {
        label: 'sidebar.collections_shared',
        subItem: true,
        active:
          currentRoute.value?.name === 'collections-list' &&
          currentRoute.value.params?.collectionType === 'shared_with_me',
        command: () =>
          navigate({
            name: 'collections-list',
            params: {collectionType: 'shared_with_me'}
          }),
      },
      {
        label: 'sidebar.collections_company',
        subItem: true,
        active:
          currentRoute.value?.name === 'collections-list' &&
          currentRoute.value.params?.collectionType === 'company',
        command: () =>
          navigate({
            name: 'collections-list',
            params: {collectionType: 'company'}
          }),
      },
    ]
  }, {
    label: 'sidebar.explore',
    icon: 'uil-search',
    active: isItemActive(['search', 'search-results']),
    command: () => {
      navigate({name: 'search'})
    },
  }, {
    label: 'sidebar.analysis',
    icon: 'uil-graph-bar',
    desktopOnly: true,
    active: isItemActive(['dashboard']),
    command: () => {
      navigate({name: 'dashboard'})
    },
  }
]
if (!userIsMember()) {
  items.value.push({
    key: 'manage',
    label: userIsAdmin() ? 'sidebar.organizations' : 'sidebar.manage',
    icon: "uil-cog",
    items: userIsManager() ? [managerManageItem] : manageItems,
    active: isItemActive(['organizations']),
    command: () => {
      if (userIsAdmin()) {
        navigate({
          name: 'organizations',
          params: {id: userOrganizationId()},
        })
      }
    }
  })
}


const planData = currentUser.getCurrentPlanTrialEndDate()

// const navigateToSettings = () => {
//   const route = userIsAdmin() ? { name: 'organizations' } : { name: 'organization', params: { id: userOrganizationId() } }
//   router.push(route)
// }

const logoutAndRedirect = () => {
  logoutUser()
}

const showProfileDialog = ref(false)
const showSupportDialog = ref(false)
const toProfile = () => {
  showProfileDialog.value = true
}
const toSupport = () => {
  showSupportDialog.value = true
}
</script>

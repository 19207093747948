<template>
  <div>
    <SideBarMenu
      v-if="menu"
      class="hidden md:flex"
    />
    <Sidebar
      v-model:visible="visible"
      :block-scroll="true"
      class="block md:hidden w-fit fixed top-0 left-0 h-full"
    >
      <template #container="{ closeCallback }">
        <SideBarMenu @close="closeCallback" />
      </template>
    </Sidebar>
    <main
      :class="{
        main: navbar,
        'w-full m-0': !menu,
        'm-0 md:ml-[300px]': menu
      }"
    >
      <NavBar
        v-if="navbar"
        @openSideBar="visible = true"
        @closeSideBar="visible = false"
      />
      <error-manager />
      <slot />
    </main>
    <Dialog
      v-model:visible="showOpenConsentModal"
      class="w-full sm:w-[736px] m-4"
      :modal="true"
      :closable="false"
      :draggable="false"
      :header="$t('agreement.title')"
    >
      <ModalAskForAgreement
        @close="() => {showOpenConsentModal = false}"
      />
    </Dialog>
  </div>
</template>

<script setup>
// import { useWindowSize } from '@vueuse/core'
import { useI18n } from 'vue-i18n';
import { ref, onMounted, defineProps } from "vue";
import Dialog from 'primevue/dialog';
import Sidebar from 'primevue/sidebar';

import SideBarMenu from '@/components/sidebar/SideBar.vue';
import ErrorManager from '@/components/ErrorManager.vue';
import NavBar from '@/components/sidebar/NavBar.vue';
import ModalAskForAgreement from '@/components/ModalAskForAgreement.vue'
import { useUserData } from '@/composables/userData.js';

// name: 'DefaultLayout',
defineProps({
  menu: { type: Boolean, default: true },
  navbar: { type: Boolean, default: true },
})

const { locale } = useI18n();
// const { width } = useWindowSize()
// console.log(width.value)

const visible = ref(false);
const showOpenConsentModal = ref(false);

onMounted(async () => {
  const { isAuthenticated, user } = await useUserData()
  if(locale.value !== user.lang){
    locale.value = user.lang
  }

  if(isAuthenticated.value && !user.made_choice && !user.date_onboarding)
  {
    showOpenConsentModal.value = true
  }
})

</script>


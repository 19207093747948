import { ref } from 'vue';
import {getInterviewByUserId, getInterviewReport, newInterview} from '@/api/user';
import {useUserData} from "@/composables/userData";
const { getUser } = useUserData();

export function useInterview() {

  const currentUser = getUser();
  const interview = ref({ status: null });
  const isLoadingInterview = ref(true);
  const interviewStatusData = ref({
    dataTest: '',
    ctaDataTest: '',
    text: '',
    textDesc: '',
    ctaLabel: ''
  });

  const loadInterviewDetails = () => {
    getInterviewByUserId(currentUser.id).then((response) => {
      if (response.error){
        isLoadingInterview.value = false;
        interview.value = { status: null };
        return ;
      }
      interview.value = response.result;
      refreshStatusData();
      isLoadingInterview.value = false;
    }).catch((e) => {
      console.error('[useInterview.vue] error: ' + e);
      isLoadingInterview.value = false;
    });
  };

  const startInterview = async () => {
    return await newInterview(currentUser.id);
  };

  const getItwReport = async () => {
    return await getInterviewReport(interview.value.id);
  };

  const refreshStatusData = () => {

      if (['CREATED', 'STARTED'].indexOf(interview.value.status) > -1) {
        interviewStatusData.value = {
          dataTest: 'interview_started',
          ctaDataTest: 'go-to-interview',
          text: 'user_profile.interview.started',
          textDesc: 'user_profile.interview.started_2',
          ctaLabel: 'user_profile.interview.started_cta',
        };
      } else if (['COMPLETED', 'PROCESSED'].indexOf(interview.value.status) > -1) {
        interviewStatusData.value = {
          dataTest: 'interview_processing',
          ctaDataTest: 'go-to-interview_processing',
          text: 'user_profile.interview.processing',
          textDesc: 'user_profile.interview.processing_2',
          ctaLabel: ''
        };
      } else if (interview.value.status === 'FAILED') {
        interviewStatusData.value = {
          dataTest: 'interview_failed',
          ctaDataTest: 'go-to-interview-after-error',
          text: 'user_profile.interview.failed',
          textDesc: 'user_profile.interview.failed_2',
          ctaLabel: 'user_profile.interview.failed_cta',
        };
      } else if (interview.value.status === 'REPORT_READY') {
        interviewStatusData.value = {
          dataTest: 'get-report',
          ctaDataTest: 'interview_completed',
          text: 'user_profile.interview.completed',
          textDesc: 'user_profile.interview.completed_2',
          ctaLabel: 'user_profile.interview.download_cta'
        };
      } else {
        interviewStatusData.value = {
          dataTest: 'start',
          ctaDataTest: 'start-interview',
          text: 'user_profile.interview.to_be_initialize',
          textDesc: '',
          ctaLabel: 'user_profile.interview.to_be_initialize_cta'
        };
      }
  };

  loadInterviewDetails();

  return {
    interview,
    interviewStatusData,
    isLoadingInterview,
    startInterview,
    getItwReport,
  };
}

export default {
  accordiontab: {
    headerAction:
      'flex flex-row-reverse justify-between border-y border-gray-200 rounded-none bg-transparent p-4',
    content: 'border-b border-gray-200 rounded-none bg-transparent p-4 ',
  },
  autocomplete: {
    input: ({ props }) => ({
      class: [
        // {
        //   'px-4 py-4': props.size === 'large',
        //   'px-2 py-2': props.size === 'small',
        //   'p-3': props.size === null,
        // },
        'p-3',
        'text-gray-600',
        'placeholder:text-gray-400',
        'bg-gray-0',
        'border',
        { 'border-gray-300': !props.invalid },
        { 'border-red-500': props.invalid },
        // States
        {
          'hover:border-primary': !props.disabled && !props.invalid,
          'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 focus:z-10':
            !props.disabled,
          'opacity-60 select-none pointer-events-none cursor-default':
            props.disabled,
        },
      ],
    }),
  },
  carousel: {
    root: {
      class: [
        // Flexbox
        'flex flex-col',
      ],
    },
    content: {
      class: [
        // Flexbox & Overflow
        'flex flex-col overflow-auto',
      ],
    },
    container: 'flex flex-row',
    previousbutton: {
      class: [
        // Flexbox & Alignment
        'flex justify-center items-center self-center cursor-pointer',

        // Sizing & Overflow
        'overflow-hidden w-8 h-8',

        // Spacing
        'mx-2',

        // Shape
        'rounded-full',

        // Border & Background
        'border-0 bg-transparent',

        // Color
        'text-gray-600',

        // Transitions
        'transition duration-200 ease-in-out',
      ],
    },
    nextbutton: {
      class: [
        // Flexbox & Alignment
        'flex justify-center items-center self-center cursor-pointer',

        // Sizing & Overflow
        'overflow-hidden w-8 h-8',

        // Spacing
        'mx-2',

        // Shape
        'rounded-full',

        // Border & Background
        'border-0 bg-transparent',

        // Color
        'text-gray-600',

        // Transitions
        'transition duration-200 ease-in-out',
      ],
    },
    itemscontent: {
      class: [
        // Overflow & Width
        'overflow-hidden w-full py-4',
      ],
    },
    itemscontainer: () => ({
      class: ['flex flex-row gap-6'],
    }),
    // item: 'flex shrink-0 grow-0 basis-1/6 w-fit sm:max-w-[50%] md:max-w-[33.333333333333336%]',
    item: ({ props }) => ({
      class: [
        // Flexbox
        'flex shrink-0 grow ',
        // Size
        {
          'w-full sm:w-[50%] md:w-[33.333333333333336%]': // w-fit
            props.orientation !== 'vertical' && props.unstyled,
          'w-full h-full': props.orientation === 'vertical' && props.unstyled,
        },
      ],
    }),
  },
  column: {
    headerCell: 'p-3',
    headerTitle: 'text-nowrap',
    bodyCell: 'p-3',
  },
  datatable: {
    // root: 'rounded-lg',
    // table: 'rounded-lg',
    header: 'bg-white border-x border-t rounded-t-lg border-gray-200',
    wrapper: 'bg-white border rounded-b-lg border-gray-200',
    // tbody: 'rounded-lg',
    // footer: 'rounded-lg',
    bodyRow: 'border-t',
  },
  paginator: {
    root: 'bg-gray-100',
    pageButton: ({ context }) => ({
        class: [
          'hover:bg-blue-100 hover:text-blue-900',
          context?.active ? 'border border-blue-600' : '',
        ]
    }),
  },
  stepper: {
    stepperpanel: {
      number: ({ context }) => ({
        class: [
          context.active
            ? 'bg-blue-700 text-primary-inverse'
            : 'border border-gray-200 text-gray-900',
        ],
      }),
    },
  },
  button: {
    root: ({ props, context, parent }) => ({
      class: [
        'relative',
        // Alignments
        'items-center inline-flex text-center align-bottom justify-center',
        // Sizes & Spacing
        'leading-[normal]',
        'rounded-large',
        'text-nowrap',
        {
          'px-4 py-3': props.size === null && props.severity !== 'interactive',
          'text-sm py-2 px-3': props.size === 'small',
          'text-xl py-3 px-4': props.size === 'large',
        },
        {
          'w-6 h-6 p-0': props.label === null && props.icon !== null,
        },
        // Shapes
        {'shadow-lg': props.raised},
        {'rounded-large': !props.rounded, 'rounded-full': props.rounded},
        {
          'rounded-none first:rounded-l-md last:rounded-r-md':
            parent.instance?.$name === 'InputGroup',
        },
        {
          'rounded-none rounded-r-md':
            ['AutoComplete'].indexOf(parent.instance?.$name) > -1 ||
            (parent.instance?.$name === 'Calendar' &&
              props.pt['data-pc-section'] === 'dropdownbutton'),
        },
        {
          'rounded-md text-white':
            ['Calendar'].indexOf(parent.instance?.$name) > -1,
        },
        // Link Button
        {'text-primary-600 bg-transparent border-transparent': props.link},
        {'no-underline': !props.link},
        // Plain Button
        {
          'text-white bg-gray-500 border border-gray-500':
            props.plain && !props.outlined && !props.text,
        },
        // Plain Text Button
        {'text-gray-500': props.plain && props.text},
        // Plain Outlined Button
        {
          'text-gray-500 border border-gray-500':
            props.plain && props.outlined,
        },
        // Text Button
        {'bg-transparent border-transparent': props.text && !props.plain},
        // Outlined Button
        {'bg-transparent border': props.outlined && !props.plain},
        // --- Severity Buttons ---
        // Primary Button
        {
          'text-white':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-blue-700':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-blue-700':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Primary Text Button
        {
          'text-blue-700':
            props.text && props.severity === null && !props.plain,
        },
        // Primary Outlined Button
        {
          'text-blue-700 border border-blue-700':
            props.outlined && props.severity === null && !props.plain,
        },
        // Secondary Button
        {
          'text-blue-900 bg-blue-100 hover:bg-blue-300 hover:shadow-wide border-2 border-transparent hover:border-transparent focus:shadow-bluelight focus:border-blue-400':
            props.severity === 'secondary' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Secondary Text Button
        {
          'text-gray-500':
            props.text && props.severity === 'secondary' && !props.plain,
        },
        // Tertiary Button
        {
          'text-blue-900 bg-transparent hover:shadow-wide border-2 border-transparent hover:border-transparent focus:shadow-wide focus:border-blue-900':
            props.severity === 'tertiary',
        },
        // Success Button
        {
          'text-white':
            props.severity === 'success' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-green-500':
            props.severity === 'success' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-green-500':
            props.severity === 'success' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Success Text Button
        {
          'text-green-500':
            props.text && props.severity === 'success' && !props.plain,
        },
        // Success Outlined Button
        {
          'text-green-500 border border-green-500 hover:bg-green-300/20':
            props.outlined && props.severity === 'success' && !props.plain,
        },
        // Info Button
        {
          'text-white':
            props.severity === 'info' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-blue-500':
            props.severity === 'info' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-blue-500':
            props.severity === 'info' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Info Text Button
        {
          'text-blue-500':
            props.text && props.severity === 'info' && !props.plain,
        },
        // Info Outlined Button
        {
          'text-blue-500 border border-blue-500 hover:bg-blue-300/20 ':
            props.outlined && props.severity === 'info' && !props.plain,
        },
        // Warning Button
        {
          'text-white':
            props.severity === 'warning' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-orange-500':
            props.severity === 'warning' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-orange-500':
            props.severity === 'warning' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Warning Text Button
        {
          'text-orange-500':
            props.text && props.severity === 'warning' && !props.plain,
        },
        // Warning Outlined Button
        {
          'text-orange-500 border border-orange-500 hover:bg-orange-300/20':
            props.outlined && props.severity === 'warning' && !props.plain,
        },
        // Help Button
        {
          'text-white':
            props.severity === 'help' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-purple-500':
            props.severity === 'help' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-purple-500':
            props.severity === 'help' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Help Text Button
        {
          'text-purple-500':
            props.text && props.severity === 'help' && !props.plain,
        },
        // Help Outlined Button
        {
          'text-purple-500 border border-purple-500 hover:bg-purple-300/20':
            props.outlined && props.severity === 'help' && !props.plain,
        },
        // Danger Button
        {
          'text-white':
            props.severity === 'danger' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-red-500':
            props.severity === 'danger' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-red-500':
            props.severity === 'danger' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Danger Text Button
        {
          'text-red-500':
            props.text && props.severity === 'danger' && !props.plain,
        },
        // Danger Outlined Button
        {
          'text-red-500 border border-red-500 hover:bg-red-300/20':
            props.outlined && props.severity === 'danger' && !props.plain,
        },
        // Contrast Button
        {
          'text-white':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-gray-900':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'border border-gray-900':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Contrast Text Button
        {
          'text-gray-900':
            props.text && props.severity === 'contrast' && !props.plain,
        },
        // Contrast Outlined Button
        {
          'text-gray-900 border border-gray-900':
            props.outlined && props.severity === 'contrast' && !props.plain,
        },
        // --- Severity Button States ---
        'focus:outline-none focus:outline-offset-0 focus:ring',
        // Link
        {'focus:ring-primary': props.link},
        // Plain
        {
          'hover:bg-gray-600 hover:border-gray-600':
            props.plain && !props.outlined && !props.text,
        },
        // Text & Outlined Button
        {
          'hover:bg-gray-300/20':
            props.plain && (props.text || props.outlined),
        },
        // Primary
        {
          'hover:bg-blue-900 hover:border-blue-900 hover:shadow-wide border-2 border-transparent focus:border-blue-900':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {'focus:ring-primary': props.severity === null},
        // Text & Outlined Button
        {
          'hover:bg-primary-300/20':
            (props.text || props.outlined) &&
            props.severity === null &&
            !props.plain,
        },
        // Success
        {
          'hover:bg-green-600 hover:border-green-600':
            props.severity === 'success' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-green-400/50':
            props.severity === 'success',
        },
        // Text & Outlined Button
        {
          'hover:bg-green-300/20':
            (props.text || props.outlined) &&
            props.severity === 'success' &&
            !props.plain,
        },
        // interactive
        {
          'w-6 h-6 p-0 text-gray-700 bg-gray-100 hover:bg-gray-300 border-transparent focus:border-blue-700 flex-shrink-0 ml-auto':
            props.severity === 'interactive'
        },
        // Info
        {
          'hover:bg-blue-600 hover:border-blue-600':
            props.severity === 'info' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-blue-400/50':
            props.severity === 'info',
        },
        // Text & Outlined Button
        {
          'hover:bg-blue-300/20':
            (props.text || props.outlined) &&
            props.severity === 'info' &&
            !props.plain,
        },
        // Warning
        {
          'hover:bg-orange-600 hover:border-orange-600':
            props.severity === 'warning' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-orange-400/50':
            props.severity === 'warning',
        },
        // Text & Outlined Button
        {
          'hover:bg-orange-300/20':
            (props.text || props.outlined) &&
            props.severity === 'warning' &&
            !props.plain,
        },
        // Help
        {
          'hover:bg-purple-600 hover:border-purple-600':
            props.severity === 'help' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-purple-400/50':
            props.severity === 'help',
        },
        // Text & Outlined Button
        {
          'hover:bg-purple-300/20':
            (props.text || props.outlined) &&
            props.severity === 'help' &&
            !props.plain,
        },
        // Danger
        {
          'hover:bg-red-600 hover:border-red-600':
            props.severity === 'danger' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-red-400/50':
            props.severity === 'danger',
        },
        // Text & Outlined Button
        {
          'hover:bg-red-300/20':
            (props.text || props.outlined) &&
            props.severity === 'danger' &&
            !props.plain,
        },
        // Contrast
        {
          'hover:bg-gray-800 hover:border-gray-800':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        {
          'focus:ring-gray-500':
            props.severity === 'contrast',
        },
        // Text & Outlined Button
        {
          'hover:bg-gray-900/10':
            (props.text || props.outlined) &&
            props.severity === 'contrast' &&
            !props.plain,
        },
        // Disabled
        {'opacity-60 pointer-events-none cursor-default': context.disabled},
        // Transitions
        'transition duration-200 ease-in-out',
        // Misc
        'cursor-pointer overflow-hidden select-none',
      ],
    }),
    label: ({ props }) => ({
      class: [
        'duration-200',
        'font-bold',
        {
          'hover:underline': props.link,
        },
        {
          'flex-1': props.label !== null,
          'invisible w-0': props.label === null,
        },
      ],
    }),
    icon: ({ props }) => ({
      class: [
        // 'mx-0',
        {
          'mr-2': props.iconPos === 'left' && props.label !== null,
          'ml-2 order-1': props.iconPos === 'right' && props.label !== null,
          'mb-2': props.iconPos === 'top' && props.label !== null,
          'mt-2': props.iconPos === 'bottom' && props.label !== null,
          // 'w-6 h-6': props.label == null,
        },
      ],
    }),
    loadingicon: ({ props }) => ({
      class: [
        'h-4 w-4',
        'mx-0',
        {
          'mr-2': props.iconPos === 'left' && props.label !== null,
          'ml-2 order-1': props.iconPos === 'right' && props.label !== null,
          'mb-2': props.iconPos === 'top' && props.label !== null,
          'mt-2': props.iconPos === 'bottom' && props.label !== null,
        },
        'animate-spin',
      ],
    }),
    badge: ({ props }) => ({
      class: [
        {
          'm-0 w-4 h-4 leading-none flex items-center justify-center':
            props.badge,
        },
        // Secondary Button
        {
          'text-blue-700 bg-blue-100':
            props.badge && props.badgeSeverity === 'secondary',
        },
      ],
    }),
  },
  checkbox: {
    root: {
      class: [
        'relative',
        'inline-flex',
        'align-bottom',
        'w-6',
        'h-6',
        'mr-2',
        'cursor-pointer',
        'select-none',
      ],
    },
    box: ({ props, context }) => ({
      class: [
        // Alignment
        'flex',
        'items-center',
        'justify-center',
        // Size
        'w-6',
        'h-6',
        // Shape
        'rounded-md',
        'border-2',
        // Colors
        {
          'border-gray-200 bg-gray-0':
            !context.checked && !props.invalid,
          'border-primary bg-primary': context.checked,
        },
        // Invalid State
        { 'border-red-500': props.invalid },
        // States
        {
          'peer-hover:border-primary':
            !props.disabled && !context.checked && !props.invalid,
          'peer-hover:bg-primary-emphasis peer-hover:border-primary-emphasis':
            !props.disabled && context.checked,
          'peer-focus-visible:border-primary-500 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20':
            !props.disabled,
          'cursor-default opacity-60': props.disabled,
        },
        {
          '[&>svg]:text-primary-contrast [&>svg]:w-[0.875rem] [&>svg]:h-[0.875rem]':
            context.checked,
        },
        // Transitions
        'transition-colors',
        'duration-200',
      ],
    }),
    input: {
      class: [
        'peer',
        'w-full ',
        'h-full',
        'absolute',
        'top-0 left-0',
        'z-10',
        'p-0',
        'm-0',
        'opacity-0',
        'rounded-md',
        'outline-none',
        'border-2 border-gray-200',
        'appearance-none',
        'cursor-pointer',
      ],
    },
    icon: ({ state, context }) => ({
      class: [
        // Font
        'text-base leading-none',
        // Size
        'w-4',
        'h-4',
        // Colors
        {
          'text-white': context.checked,
          'text-primary': state.d_indeterminate,
        },
        // Transitions
        'transition-all',
        'duration-200',
      ],
    }),
  },
  multiselect: {
    root: ({ props, state }) => ({
      class: [
        // Display and Position
        'inline-flex',
        'relative',

        // Shape
        'rounded-md',

        // Color and Background
        'bg-gray-0',
        'border',
        { 'border-gray-300': !props.invalid },

        // Invalid State
        { 'border-red-500': props.invalid },

        // Transitions
        'transition-all',
        'duration-200',

        // States
        { 'hover:border-primary': !props.invalid },
        {
          'outline-none outline-offset-0 ring ring-primary-400/50':
            state.focused,
        },

        // Misc
        'cursor-pointer',
        'select-none',
        {
          'opacity-60': props.disabled,
          'pointer-events-none': props.disabled,
          'cursor-default': props.disabled,
        },
      ],
    }),
    filterInput: {
      class: ['text-gray-500', 'shadow-sm', 'py-3', 'px-5', 'rounded-tl-md', 'rounded-tr-md',]
    },
  },
  calendar: {
    input: ({ props, context, parent }) => {
      var _a, _b, _c, _d
      return {
        class: [
          // Font
          'leading-[normal]',
          // Flex
          { 'flex-1 w-[1%]': parent.instance?.$name === 'InputGroup' },
          // Spacing
          'm-0',
          { 'w-full': props.fluid },
          // Size
          'p-3',
          // Colors
          'text-gray-600',
          'placeholder:text-gray-400',
          'bg-gray-0',
          'border',
          { 'border-gray-300': !props.invalid },
          // Invalid State
          { 'border-red-500': props.invalid },
          // States
          {
            'hover:border-primary': !props.disabled && !props.invalid,
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 focus:z-10':
              !props.disabled,
            'opacity-60 select-none pointer-events-none cursor-default':
              props.disabled,
          },
          // Filled State *for FloatLabel
          {
            filled:
              (((_a = parent.instance) === null ? void 0 : _a?.$name) === 'FloatLabel' &&
                context.filled) ||
              (((_c =
                (_b = parent.instance) === null ? void 0 : _b.$parentInstance) === null
                ? void 0 : _c?.$name) === 'FloatLabel' &&
                parent.props.modelValue !== null &&
                ((_d = parent.props.modelValue) === null
                  ? void 0 : _d.length) !== 0),
          },
          // Misc
          'appearance-none',
          'transition-colors duration-200',
        ],
      }
    },
  },
  textarea: {
    root: ({ props, context, parent }) => {
      var _a, _b, _c, _d
      return {
        class: [
          // Font
          'leading-[normal]',
          // Flex
          { 'flex-1 w-[1%]': parent.instance?.$name === 'InputGroup' },
          // Spacing
          'm-0',
          { 'w-full': props.fluid },
          // Size
          {
            'px-4 py-4': props.size === 'large',
            'px-2 py-2': props.size === 'small',
            'p-3': props.size == null,
          },
          // Shape
          { 'rounded-md': parent.instance?.$name !== 'InputGroup' },
          {
            'first:rounded-l-md rounded-none last:rounded-r-md':
              parent.instance?.$name === 'InputGroup',
          },
          {
            'border-0 border-y border-l last:border-r':
              parent.instance?.$name === 'InputGroup',
          },
          {
            'first:ml-0 -ml-px':
              parent.instance?.$name === 'InputGroup' && !props.showButtons,
          },
          // Colors
          'text-gray-600',
          'placeholder:text-gray-400',
          'bg-gray-0',
          'border',
          { 'border-gray-300': !props.invalid },
          // Invalid State
          { 'border-red-500': props.invalid },
          // States
          {
            'hover:border-primary': !context.disabled && !props.invalid,
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 focus:z-10':
              !context.disabled,
            'opacity-60 select-none pointer-events-none cursor-default':
              context.disabled,
          },
          // Filled State *for FloatLabel
          {
            filled:
              (((_a = parent.instance) === null ? void 0 : _a?.$name) === 'FloatLabel' && context.filled) ||
              (((_c =
                (_b = parent.instance) === null ? void 0 : _b.$parentInstance) === null
                ? void 0
                : _c?.$name) === 'FloatLabel' &&
                parent.props.modelValue !== null &&
                ((_d = parent.props.modelValue) == null
                  ? void 0
                  : _d.length) !== 0),
          },
          // Misc
          'appearance-none',
          'transition-colors duration-200',
        ],
      }
    },
  },
  inputnumber: {
    root: ({props}) => {
      return {
        class: [
          'border',
          'rounded-md',
          { 'border-gray-300': !props.invalid },
          // Invalid State
          { 'border-red-500': props.invalid },
        ]
      }
    }
  },
  inputtext: {
    root: ({ props, context, parent }) => {
      var _a, _b, _c, _d;
      return {
        class: [
          // Font
          'leading-[normal]',
          // Flex
          { 'flex-1 w-[1%]': parent.instance?.$name === 'InputGroup' },
          // Spacing
          'm-0',
          { 'w-full': props.fluid },
          // Size
          {
            'px-4 py-4': props.size === 'large',
            'px-2 py-2': props.size === 'small',
            'p-3': props.size === null,
          },
          // Shape
          { 'rounded-md': parent.instance?.$name !== 'InputGroup' },
          {
            'first:rounded-l-md rounded-none last:rounded-r-md':
              parent.instance?.$name === 'InputGroup',
          },
          {
            'border-0':parent.instance?.$name === 'InputNumber',
            'border-0 border-y border-l last:border-r':
              parent.instance?.$name === 'InputGroup',
          },
          {
            'first:ml-0 -ml-px':
              parent.instance?.$name === 'InputGroup' && !props.showButtons,
          },
          // Colors
          'text-gray-600',
          'placeholder:text-gray-400',
          'bg-gray-0 ',
          'border',
          { 'border-gray-300': !props.invalid },
          // Invalid State
          { 'border-red-500': props.invalid },
          // States
          {
            'hover:border-primary': !context.disabled && !props.invalid,
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 focus:z-10':
              !context.disabled,
            'opacity-60 select-none pointer-events-none cursor-default':
              context.disabled,
          },
          // Filled State *for FloatLabel
          {
            filled:
              (((_a = parent.instance) === null ? void 0 : _a?.$name) === 'FloatLabel' &&
                context.filled) ||
              (((_c =
                (_b = parent.instance) === null ? void 0 : _b.$parentInstance) === null
                ? void 0
                : _c?.$name) === 'FloatLabel' &&
                parent.props.modelValue !== null &&
                ((_d = parent.props.modelValue) === null
                  ? void 0
                  : _d.length) !== 0),
          },
          // Misc
          'appearance-none',
          'transition-colors duration-200',
        ],
      }
    },
  },
  radiobutton: {
    root: {
      class: [
        'relative',
        'inline-flex',
        'align-bottom',
        'w-[1.571rem] h-[1.571rem]',
        'cursor-pointer',
        'select-none',
      ],
    },
    box: ({ props }) => ({
      class: [
        // Flexbox
        'flex justify-center items-center',
        // Size
        'w-[1.571rem] h-[1.571rem]',
        // Shape
        'border-2',
        'rounded-full',
        // Transition
        'transition duration-200 ease-in-out',
        // Colors
        {
          'text-gray-700':
            props.value !== props.modelValue && props.value !== void 0,
          'bg-gray-0':
            props.value !== props.modelValue && props.value !== void 0,
          'border-gray-100':
            props.value !== props.modelValue &&
            props.value !== void 0 &&
            !props.invalid,
          'border-primary':
            props.value === props.modelValue && props.value !== void 0,
          'bg-primary':
            props.value === props.modelValue && props.value !== void 0,
        },
        // Invalid State
        { 'border-red-500': props.invalid },
        // States
        {
          'peer-hover:border-primary':
            !props.disabled && !props.invalid,
          'peer-hover:border-primary-emphasis peer-hover:bg-primary-emphasis':
            !props.disabled &&
            props.value === props.modelValue &&
            props.value !== void 0,
          'peer-focus-visible:border-primary-500 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20':
            !props.disabled,
          'opacity-60 cursor-default': props.disabled,
        },
      ],
    }),
    input: {
      class: [
        'peer',
        'w-full ',
        'h-full',
        'absolute',
        'top-0 left-0',
        'z-10',
        'p-0',
        'm-0',
        'opacity-0',
        'rounded-md',
        'outline-none',
        'border-2 border-gray-200',
        'appearance-none',
        'cursor-pointer',
      ],
    },
    icon: ({ props }) => ({
      class: [
        'block',
        // Shape
        'rounded-full',
        // Size
        'w-[0.857rem] h-[0.857rem]',
        // Colors
        'bg-gray-0',
        // Conditions
        {
          'backface-hidden scale-10 invisible':
            props.value !== props.modelValue,
          'transform visible scale-[1.1]': props.value === props.modelValue,
        },
        // Transition
        'transition duration-200',
      ],
    }),
  },
  dropdown: {
    root: ({ props, state, parent }) => ({
      class: [
        // Display and Position
        'inline-flex',
        'relative',
        // Shape
        { 'rounded-md': parent.instance?.$name !== 'InputGroup' },
        {
          'first:rounded-l-md rounded-none last:rounded-r-md':
            parent.instance?.$name === 'InputGroup',
        },
        {
          'border-0 border-y border-l last:border-r':
            parent.instance?.$name === 'InputGroup',
        },
        {
          'first:ml-0 ml-[-1px]':
            parent.instance?.$name === 'InputGroup' && !props.showButtons,
        },
        // Color and Background
        'bg-gray-0',
        'border',
        { 'border-gray-300': !props.invalid },
        // Invalid State
        'invalid:focus:ring-red-200',
        'invalid:hover:border-red-500',
        { 'border-red-500': props.invalid },
        // Transitions
        'transition-all',
        'duration-200',
        // States
        { 'hover:border-primary': !props.invalid },
        {
          'outline-none outline-offset-0 ring ring-primary-400/50':
            state.focused,
        },
        // Misc
        'cursor-pointer',
        'select-none',
        {
          'opacity-60': props.disabled,
          'pointer-events-none': props.disabled,
          'cursor-default': props.disabled,
        },
      ],
    }),
    label: ({ props, parent }) => {
      var _a
      return {
        class: [
          //Font
          'leading-[normal]',
          // Display
          'block',
          'flex-auto',
          // Color and Background
          'bg-transparent',
          'border-0',
          {
            'text-gray-800': props.modelValue !== null,
            'text-gray-400': props.modelValue === null,
          },
          'placeholder:text-gray-400',
          // Sizing and Spacing
          'w-[1%]',
          'p-3',
          { 'pr-7': props.showClear },
          //Shape
          'rounded-none',
          // Transitions
          'transition',
          'duration-200',
          // States
          'focus:outline-none focus:shadow-none',
          // Filled State *for FloatLabel
          {
            filled:
              ((_a = parent.instance) === null ? void 0 : _a?.$name) === 'FloatLabel' && props.modelValue !== null,
          },
          // Misc
          'relative',
          'cursor-pointer',
          'overflow-hidden overflow-ellipsis',
          'whitespace-nowrap',
          'appearance-none',
        ],
      }
    },
    dropdown: {
      class: [
        'flex items-center justify-center',
        'shrink-0',
        'bg-transparent',
        'text-gray-500',
        'w-12',
        'rounded-tr-md',
        'rounded-br-md',
      ],
    },
    overlay: {
      class: [
        'absolute top-0 left-0',
        'border-0',
        'rounded-md',
        'shadow-md',
        'bg-gray-0',
        'text-gray-800',
      ],
    },
    listContainer: {
      class: ['max-h-[200px]', 'overflow-auto'],
    },
    list: {
      class: 'py-3 list-none m-0',
    },
    option: ({ context }) => ({
      class: [
        // Font
        'font-normal',
        'leading-none',
        // Position
        'relative',
        'flex items-center',
        // Shape
        'border-0',
        'rounded-none',
        // Spacing
        'm-0',
        'py-3 px-5',
        // Colors
        {
          'text-gray-700': !context.focused && !context.selected,
          'bg-gray-200': context.focused && !context.selected,
          'bg-highlight': context.selected,
        },
        //States
        {
          'hover:bg-gray-100': !context.focused && !context.selected,
        },
        { 'hover:bg-highlight-emphasis': context.selected },
        'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-400/50',
        // Transitions
        'transition-shadow',
        'duration-200',
        // Misc
        { 'pointer-events-none cursor-default': context.disabled },
        { 'cursor-pointer': !context.disabled },
        'overflow-hidden',
        'whitespace-nowrap',
      ],
    }),
    optionGroup: {
      class: [
        'font-bold',
        'm-0',
        'py-3 px-5',
        'text-gray-800',
        'bg-gray-0',
        'cursor-auto',
      ],
    },
    filterInput: {
      style: 'color: var(--gray-500)',
      class: ['text-gray-500', 'shadow-sm', 'py-3', 'px-5', 'rounded-tl-md', 'rounded-tr-md',]
    },
    optionCheckIcon:
      'relative -ms-1.5 me-1.5 text-gray-700 w-4 h-4',
    optionBlankIcon: 'w-4 h-4',
    emptyMessage: {
      class: [
        'leading-none',
        'py-3 px-5',
        'text-gray-800',
        'bg-transparent',
      ],
    },
    panel: {
      class: [
        'my-2',
        // 'mx-4',
      ]
    },
    header: {
      class: [
        'p-0',
        'm-0',
        'border-b',
        'text-gray-700',
        'border-gray-300',
      ],
    },
    clearIcon: {
      class: ['text-gray-500', 'absolute', 'top-1/2', 'right-12', '-mt-2'],
    },
    loadingIcon: {
      class: 'text-gray-400 animate-spin',
    },
    transition: {
      enterFromClass: 'opacity-0 scale-y-[0.8]',
      enterActiveClass:
        'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
      leaveActiveClass: 'transition-opacity duration-100 ease-linear',
      leaveToClass: 'opacity-0',
    },
  },
  tabmenu: {
    menu: 'border-b-2 border-gray-200 flex gap-6',
    action: ({ context, state }) => ({
      class: [
        'border-b-2',
        'px-4 py-2',
        {
          'border-blue-700 text-blue-700':
            state.d_activeIndex === context.index,
          'border-transparent': state.d_activeIndex !== context.index,
        },
      ],
    }),
  },
  menu: {
    content: 'no-underline'
  },
  panelmenu: {
    headerContent: ({ context }) => {
      return {
        class: [
          'px-4',
          'py-2',
          'bg-white',
          'rounded-lg',
          'hover:bg-gray-100',
          // 'focus:border',
          // 'focus:border-blue-700',
          {
            'bg-gray-100': context.disabled
          },
          {
            'text-blue-700 font-bold': context.active,
            'text-gray-900 font-normal': !context.active,
          }
        ]
      }
    },
    content: ({ context }) => {
      return {
        class: [
          'px-4',
          'py-2',
          'bg-white',
          'rounded-lg',
          'text-gray-900',
          'hover:bg-gray-100',
          {
            'bg-gray-100': context.disabled
          },
          {
            'border border-blue-700': context.focus
          },
          {
            'font-bold': context.active,
            'font-normal': !context.active,
          }
        ]
      }
    },
    headerIcon: ({ context }) => {
      return {
        class: [{'text-gray-400': context.disabled}]
      }
    },
    submenuIcon: ['flex-left'],
    icon: ({ context }) => {
      return {
        class: [{'text-gray-400': context.disabled}]
      }
    },
    submenu: 'pl-4',
    headerAction: ({ context }) => {
      return {
        class: [
          'no-underline',
          'font-bold',
          'p-2',
          'rounded-lg',
          'border',
          'focus:outline-none',
          'border-transparent',
          'border-solid',
          'focus:border-blue-700',
          {
            'text-blue-700 font-bold': context.active,
            'text-gray-900 font-normal': !context.active,
          }
        ]
      }
    },
    label: ({ context }) => {
      return {
        class: [
          'no-underline',
          'text-sm',
          'rounded-lg',
          {
            'text-blue-700 font-bold': context.active,
            'text-gray-900 font-normal': !context.active
          }
        ]
      }
    }
  },
  dialog: {
    title: 'text-xl sm:text-2xl',
    closeButton: 'w-6 h-6 p-[0!important] text-gray-700 bg-gray-100 hover:bg-gray-300 hover:border-transparent focus:border-blue-700'
  }
}

import TrackedModel from "@/resources/TrackedModel";
import {useUserRoles} from "@/composables/userData";

export default class Subscription extends TrackedModel {

  constructor(data) {
    const today = new Date();
    const { userIsAnyAdmin } = useUserRoles();

    super(data);
    this.user = data.user || "";
    this.plan = data.plan || "";
    this.subscription = data.subscription || "";
    this.subscription_display = data.subscription_display || "Not Valid or Available";
    this.user_first_name = data.user_first_name || "";
    this.user_last_name = data.user_last_name || "";
    this.is_expirable = data.is_expirable || false; // default: true for user.role == CONSUMER, false otherwise.
    this.start_date = data.start_date;
    this.expiration_date = data.expiration_date;
    this.stripe_subscription_id = data.stripe_subscription_id || null;
    this.stripe_customer_id = data.stripe_customer_id || null;
    this.stripe_price_id = data.stripe_price_id || null;
    this.plan_status = data.plan_status || 'expired'; // [active, pending, expired] is useful trial?
    this.plan_payment_date = data.plan_payment_date;
    this.plan_frequency = data.plan_frequency;

    const plan_expiration_date = data.plan_expiration_date ? TrackedModel.asDate(data.plan_expiration_date) : null;
    this.plan_expiration_date = plan_expiration_date;
    this.plan_renewal_date =  data.plan_renewal_date ? TrackedModel.asDate(data.plan_renewal_date) : null;
    this.plan_renewal_date_str = data.plan_renewal_date ? TrackedModel.asOutputDate(this.plan_renewal_date, 'it') : null;
    this.plan_trial_end_date = data.plan_trial_end_date ? TrackedModel.asDate(data.plan_trial_end_date) : TrackedModel.asDate(data.plan_expiration_date);

    this.isInTrial = this.plan_trial_end_date > today;

    // -5gg (today +5gg)
    let lastTrialDays = new Date(new Date(new Date().setDate(new Date().getDate() +5)).setHours(2,0,0,0));
    this.trialWarning = false
    if (this.plan_trial_end_date && this.plan_trial_end_date <= TrackedModel.asDate(lastTrialDays)) {
      this.trialWarning = true;
    }

    this.showModalForExpired = ['expired'].indexOf(this.plan_status) > -1 && userIsAnyAdmin();
    this.payed = !!data.plan_payment_date;
      if(this.expiration_date && TrackedModel.asDate(data.expiration_date) < today){
        this.disabled = true;
      }
    return this;
  }
  getDetails() {
    return this.subscription_display + ' ( '+ TrackedModel.asOutputDate(this.start_date, 'it') +' - '+TrackedModel.asOutputDate(this.expiration_date, 'it')+')';
  }

  // convertForListBoxOption() {
  //   return {
  //     key: this.id,
  //     label: this.subscription_display + ' (Duration '+ this.getDurationStr()+' Used '+this.used_license+'/'+this.total_license+')',
  //     subscription: this.subscription
  //   };
  // }

  static fromApiResult(subscriptionData) {
    const subscriptionObj = new Subscription(subscriptionData);
    return subscriptionObj;
  }
}

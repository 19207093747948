import BaseModel from "@/resources/BaseModel";

export default class CollectionSystem extends BaseModel {

  constructor(data) {
    super(data);
    this.id = data.id || "NO_ID"; // new UUID()
    this.title = data.title || "";
    this.description = data.description || "";

    this.total = data.total || 0;
    this.completed = data.completed || 0;
    this.resources = data.resources || [];
    this.systemType = data.system_type || "";
    this.source = data.source || "";
    this.classification = data.source === 'occupation' ? data.system_info : 'skill';
    this.skillName = data.source === 'skill' ? data.system_info : null;
    this.systemTitle = data.source === 'interview' ? 'interview' : (data.source === 'skill' ? this.skillName : '');

    // this.occupationName = data.occupationName;
    // this.processTitle();
    return this;
  }
  // processTitle() {
  //   if(this.title.indexOf('|') > -1) {
  //     const parts = this.title.split('|');
  //     this.source = parts[0]; // occupation|skill
  //     if (this.source === 'occupation') {
  //       this.classification = parts[1];
  //       this.occupationId = parts.length > 2 ? parts[2] : '';
  //       this.occupationName = this.description;
  //       this.systemTitle = null;
  //       this.systemType = 'occupation' + (this.classification ? '_'+this.classification : '');
  //     } else if (this.source === 'interview') {
  //       this.classification = parts[0];
  //       this.systemTitle = parts[1];
  //       this.systemType = 'interview';
  //     } else {
  //       this.classification = null;
  //       this.skillId = parts[1];
  //       this.skillName = this.description;
  //       this.systemTitle = this.skillName;
  //       this.description = 'collections.system_skill_desc';
  //       this.systemType = 'skill';
  //     }
  //   }
  // }
  static fromApiResult(coll) {
    const collection = new CollectionSystem(coll);

    return collection;
  }
}
